import React, { useState } from "react";
import "../../css/ColorPicker.scss";
function useColorPicker(startingColors = {}) {
    //colors(/starting colors) is object of colors; {color1: #hex, color2: #hex, etc}
    let defaultColors = ["#66DACB", "#FC0B7A", "#0B57FC", "#0C1731"]
    const [colors, setColors] = useState(startingColors);
    function handleColorChange(newColor, colorKey) {
        setColors(colors => {
            const newColors = { ...colors };
            newColors[colorKey] = newColor;
            return newColors;
        });
    }
    const colorPickerJSX = (
        <div className="colorPickers">
            {Object.keys(colors).map(colorKey => (
                <label className="colorPickerContainer" key={colorKey}>
                    {/* <span className="colorName">{colorKey}: </span> */}
                    <ColorPicker
                        color={colors[colorKey]}
                        setColor={newColor => handleColorChange(newColor, colorKey)}
                    />
                    <label htmlFor="cPicker"><img src="/icon/colorCircle.svg" alt="color circle" /></label>
                </label>
            ))}
            {defaultColors.map(color => {
                return <div id={color} style={{ backgroundColor: color }} key={color} onClick={e => handleColorChange(color, Object.keys(colors)[0])} />
            })}
        </div>
    );
    return [colors, colorPickerJSX];
}

function ColorPicker({ color, setColor }) {
    //will be designed later
    return <input type="color" value={color} onChange={event => setColor(event.target.value)} id="cPicker" />;
}

export default useColorPicker;

//array form
// function useColorPicker(startingColors = ["#888888"]) {
//     // const [color, setColor] = useState(startingColor);
//     const [colors, setColors] = useState(startingColors);
//     function handleColorChange(newColor, index) {
//         setColors(colors => {
//             const newColors = [...colors];
//             newColors[index] = newColor;
//             return newColors;
//         });
//     }
//     const colorPickerJSX = colors.map((color, index) => (
//         <ColorPicker color={color} setColor={newColor => handleColorChange(newColor, index)} />
//     ));
//     return [colors, colorPickerJSX];
// }

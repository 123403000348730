import React from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "../../css/GamePage.scss";
import editModes from "../editModes";
/*
takes a component as a prop (GameBuilder) and renders it with some siblings and props. 
the component should be a GameBuilder (ie Bingo or Quadruplets)
renders Topbar, Sidebar and props.GameBuilder.
GameBuilder is passed the current editMode (ie "view" or "edit")

exists because
a) GameBuilders are supposed to have a topbar and sidebar 
b) the mode is the same for all of them
c) writing it for all of them would make changin it harder and be a lot of boilerplate

also gets a "game" prop which is the name of the game passed and is used to access the games object in gameTypes.js
*/
function GamePage({ children, mode, setMode, gameInfo, save }) {
  //an array of the edit modes and the icon (icons from FontAwesome) theyre displayed with.
  // const [mode, setMode] = useState(editModes.EDIT)
  const { name: gameName, color: textColor, value: value } = gameInfo;
  return (
    <div className="gamePage">
      <img src="/icon/logoNew.png" alt="logo" className="logoPrintPg" />
      {children}
      {/* <props.GameBuilder mode={mode} classes="gamePageMain" editModes={editModes} setMode={setMode} /> */}
      <Topbar
        textColor={textColor}
        gameName={gameName}
        modeName={mode.name}
        value={value}
      />
      <Sidebar
        mode={mode}
        setMode={setMode}
        editModes={editModes}
        save={save}
      />
      <div className="bottomLink">https://funlearning.co.il</div>
    </div>
  );
}
export default GamePage;
// export { GamePage2, GamePage as default };

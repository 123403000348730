import { useEffect } from "react";
import editModes from "../editModes";

/**
 * @typedef {Object} Mode
 * @property {string} name
 * @property {string} icon - name of FontAwesomeIcon
 */

/**
 * simple effect to call window.print() when the mode is print
 * @param {Mode} mode the curent mode
 */
function usePrintMode(mode) {
    useEffect(() => {
        if (mode === editModes.PRINT) {
            //set timeout or else confirmation box will still be on screen
            setTimeout(() => window.print(), 50);
        }
    }, [mode]);
}
export default usePrintMode;

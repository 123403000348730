//list of games and the text colors associated with them
//in a separate file becaused used in multiple places
//has a name prop instead of using the key because in one place "Game Cards" is wnated and in another "Cards"

const gameTypes = Object.freeze({
  "Card Game": { name: "Card Game", color: "#0B57FC", value: "cardGame" },
  Domino: { name: "Domino", color: "#66DACB", value: "domino" },
  Bingo: { name: "Bingo", color: "#FFC400", value: "bingo" },
  "Go Fish": { name: "Go Fish", color: "#FC0B7A", value: "goFish" },
  Cube: { name: "Cube", color: "#00B9F3", value: "cube" },
});

export default gameTypes;

import { useState } from "react";
import editModes from "../editModes";
import useConfirmationDialog from "./useConfirmationDialog";
//handles mode state updates.
//takes flaggedModes and checks to be run if one of them is passed as new mode.
//if checks fails pops up a confirmation dialog with confirmation to check if user wants to continue
//if not then does nothing

/**
 * @typedef {Object} Mode
 * @property {string} name
 * @property {string} icon - name of FontAwesomeIcon
 */

/**
 * a hook that handles hook state updating, with the ability to flag certain GameBuilder modes changes and provide extra checks for them.
 * @param {Mode[]} flaggedModes - a list of modes (see editModes) to be checked before updating to {}
 * @param {boolean[]} checks - a list of checks to run before updating to flagged modes. if failed request user confirmation.
 * @param {string} confirmationQuestion - text of the question to ask user if flaggedMode update fails checks.
 * @param {Mode} [startingMode] - the mode on start. default is edit.
 *
 * @returns {[mode, handleModeChange, confirmationDialogJSX]} the current mode, a function to update it, and a confirmationDialog for mode switches that user confirmation is requested
 */
function useModes(flaggedModes, checks, confirmationQuestion, startingMode = editModes.EDIT) {
    // const [responseValue, setResponseValue] = useState();
    const [mode, setMode] = useState(startingMode);
    const [confirmationDialogJSX, openConfirmationDialog] = useConfirmationDialog(handleConfirmationDialogAnswer);
    /**
     * takes incoming mode change. runs checks. if fail opens confirmation dialog. if doesnt fail sets new mode
     * @param {Mode} newMode
     */
    function handleModeChange(newMode) {
        //change mode if not print or categories is greater than boardCells
        if (!flaggedModes.includes(newMode) || checks.every(check => check)) {
            setMode(newMode);
        } else {
            openConfirmationDialog(confirmationQuestion, newMode);
        }
    }

    function handleConfirmationDialogAnswer(answer, newMode) {
        if (answer) setMode(newMode);
    }

    return [mode, handleModeChange, confirmationDialogJSX];
}

export default useModes;

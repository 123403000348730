import React from "react";
//an file input field with some props.
//its display is hidden so must be wrapped with a label to be used
//file input. only accepts images. passes back an ObjectURL calls the onChange function and passes back just the image selected;
const ImageInput = ({ onChange }) => {
    const handleChange = event => {
        //check passed fileList
        if (!event.target.files[0]) {
            onChange(null);
            return;
        }
        const file = event.target.files[0];

        //if file is not an image then return
        if (!file.type.startsWith("image/")) {
            console.error("you may only load images");
            return;
        }
        onChange(file);
    };
    return <input style={{ display: "none" }} accept="image/*" type="file" onChange={handleChange} />;
};

export default ImageInput;

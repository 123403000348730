import React from "react";
import CategoryBuilder from "./CategoryBuilder";
import "../../css/CategoriesBuilder.scss";
import useConfirmationDialog from "../Assorted/useConfirmationDialog";
import { useTranslate } from "../../translation/GPi18n";

/*
CategoriesBuilder creates sets of inputs. 
    it is given the size of a category(categorySize) and creates objects with that nany fields
    each field is able to be set to either an image or text.
    it allows users to create as many categories as they want
    categories are passed back to the container which then builds the games with the data.

categories builder gets: 
    categorySize: object with size of categories to be built and whether it is variable ({size: number, variable: boolean})
    addCategoryIconSrc, addCategoryText: the text and icon for the text to add a category
        exist because one component wants a different text and icon than the others
    setCategories: a function to set the/pass back the changes into the parent component
    categories: the current list of categories. passed as prop instead of in state because parent component needs to know it
    default of categories is an array

CATEGORY OBJECTS:
    categories are stored as an array of objects
    each categoryObject has a name, key(exists for purposes of putting as key in react so can display them) 
        and array of inputs.
    an input is an object of the form {type: "ds", value: ""}
    inputs type can have values [text, image]
    default values set when adding a category

CategoryBuilder:
    overall it gets the inputs and name. it registers any changes puts them in the right form and calls setCategoryData
    setCategoryData is called with an updateObject which the parent merges with the data of that category 
    setCategoryData knows which category to update because the function is passed with index already set as an argument (updates => setCategoryData(index, updatess))

    gets:
        name, inputs: the name and the inputs
        setCategoryData: a function that it calls to set changes to the data of the category
        handleDeleteCategory: a function it calls to delete self when the delete button is press

CategoryBuilderInput: 
    gets an input object and lets the user set it.
    is rendered by CategoryBuilder
    returns the set inputObject
        has three states based on the current type of the input object
        "text": shows a basic text field
        "image": goes to file and select animage which is displayed
        anythingElse(typically: ""): shows a box with icons that change type to "text" and "image"
            the one to change to image opens a file picker to let the user select an image
*/

// possible TODO: make things like category and category inputs actual objects (with constructor.factor function and stuff)
//instead of just pushing it on with certain values given (eg createCategory(v1,v2) instead of  having {k: p, k:p})

function CategoriesBuilder({
  displayName = true,
  isNameEditable = true,
  categorySize,
  variableSizeCategories,
  setCategories,
  categories,
  isBingo,
  addCategoryIconSrc = "/icon/cards.svg",
}) {
  const setCategoryData = (index, updatesObject) => {
    const categoriesCopy = [...categories];
    categoriesCopy[index] = { ...categoriesCopy[index], ...updatesObject };
    setCategories(categoriesCopy);
  };

  const addCategory = () => {
    let newPHolderNum =
      categories.length > 0
        ? JSON.parse(
            categories[categories.length - 1].pHolder.match(/\d+/)[0]
          ) + 1
        : 1;
    setCategories(
      categories.concat({
        key: Math.random(),
        inputs: Array(categorySize).fill({ type: "", value: "" }),
        name: "",
        pHolder: `${isBingo ? t("note") : ""} ${newPHolderNum}  - ${t(
          "enterTitle"
        )}`,
      })
    );
  };

  const { t } = useTranslate();

  //handling of deleting categories
  const [
    confirmDeleteDialogJSX,
    openConfirmDeleteDialog,
  ] = useConfirmationDialog(handleConfirmDeleteDialogAnswer);
  const deleteCategoryPressed = (index) => {
    openConfirmDeleteDialog(
      `Are you sure you want to delete category ${index + 1}: ${
        categories[index].name
      }?`,
      index
    );
  };
  function handleConfirmDeleteDialogAnswer(answer, index) {
    if (answer) {
      let categoriesCopyx = [...categories];
      categoriesCopyx.splice(index, 1);
      let categoriesCopy = categoriesCopyx.map((category, i) => {
        let newPh = category.pHolder.replace(/\d+/g, i + 1);
        let catCopy = { ...category, pHolder: newPh };
        return catCopy;
      });
      //renumber if names not editable
      if (!isNameEditable) {
        categoriesCopy = categoriesCopy.map((category, index) => ({
          ...category,
          name: ` ${index + 1}`, //shalva deleted the word "category"
        }));
      }
      setCategories(categoriesCopy);
    }
  }
  //end of handling of deleting categories

  return (
    <>
      <div
        className={
          "categoriesBuilder" + (categories.length > 0 ? "" : " emptyContainer")
        }
      >
        {categories.length > 0 ? (
          categories.map((category, index) => (
            <CategoryBuilder
              displayName={displayName}
              isNameEditable={isNameEditable}
              isSizeVariable={variableSizeCategories}
              category={category}
              key={category.key}
              // inputs={category.inputs}
              // name={category.name}
              setCategoryData={(updatesObject) =>
                setCategoryData(index, updatesObject)
              }
              handleDeleteCategory={() => deleteCategoryPressed(index)}
            />
          ))
        ) : (
          <div>
            {t("click")} <br />+ <br />
            {t("clickCreate")}
          </div>
        )}
        <div className="addCategoryBtn" onClick={addCategory}>
          {/* <img className="addCategoryIcon" src={addCategoryIconSrc} alt="add Category Icon" /> */}
          {/* <span className="text">{"Add a new category"}</span> */}
          <span className="plusSign">+</span>
        </div>
      </div>
      {confirmDeleteDialogJSX}
    </>
  );
}

export default CategoriesBuilder;

//possibly TODO : let user put in image or text (what exactly would text look like?) in GameCard
import React, { useReducer, useEffect, useState, useContext } from "react";
import "../../css/GameCards.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLogo from "../Assorted/useLogo";
import useDesignSidebar from "../Assorted/useDesignSidebar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useColorPicker from "../Assorted/useColorPicker";
import SingleColorPicker from "../Assorted/useSingleColorPicker";
import ImagesPopup from "../Assorted/imagesPopup";
import RecoverPopup from "../Assorted/recoverPopup";
import editModes from "../editModes";
import GamePage from "../GamePage/GamePage";
import usePrintMode from "../Assorted/usePrintMode";
import { useTranslate } from "../../translation/GPi18n";
import gameTypes from "../../components/gameTypes";

const cardActionTypes = Object.freeze({
  RECOVER_CARDS: "recoverCards",
  ADD_CARD: "addCard",
  DELETE_CARD: "deleteCard",
  EDIT_CARD: "editCard",
  MOVE_CARD: "moveCard",
});

function cardsReducer(state, action) {
  switch (action.type) {
    case cardActionTypes.RECOVER_CARDS: {
      return {
        ...state,
        cardsCount: action.lsItems.cardsCount,
        cards: action.lsItems.cards,
      };
    }
    case cardActionTypes.ADD_CARD: {
      const newCard = {
        main: {
          type: "",
          value: "",
        },
        subtext: "",
        color: "#66DACB",
        id: state.cardsCount + 1,
      };
      return {
        ...state,
        cardsCount: state.cardsCount + 1,
        cards: [...state.cards, newCard],
      };
    }
    case cardActionTypes.DELETE_CARD: {
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== action.cardId),
      };
    }
    case cardActionTypes.EDIT_CARD: {
      const cardsCopy = [...state.cards];
      const cardIndex = cardsCopy.findIndex(
        (card) => card.id === action.cardId
      );
      cardsCopy[cardIndex] = { ...cardsCopy[cardIndex], ...action.changes };
      return { ...state, cards: cardsCopy };
    }
    //for drag and drop.
    case cardActionTypes.MOVE_CARD: {
      const { fromIndex, toIndex } = action;
      const cardsCopy = [...state.cards];
      const movedCard = cardsCopy.splice(fromIndex, 1)[0];
      cardsCopy.splice(toIndex, 0, movedCard);
      return { ...state, cards: cardsCopy };
    }
    default: {
      return state;
    }
  }
}
//color picker
const defaultColors = {
  cardSubtext: "#66dacb",
};
function GameCards({ gameInfo }) {
  const [logo, logoJSX] = useLogo();
  //Color picker
  const [colors, colorPickerJSX] = useColorPicker(defaultColors);
  const [{ frame, vector }, designSidebarJSX] = useDesignSidebar(
    undefined,
    colorPickerJSX
  ); //second argument is DUMMY to let color picker be displayed temporarily
  const [gameTitle, setGameTitle] = useState("");
  const [cardsState, cardsDispatch] = useReducer(cardsReducer, {
    cards: [],
    cardsCount: 0,
  });
  // const [mode, setMode] = useState(editModes.EDIT);
  // reducer so dont need to creater wrapper function around setMode.
  const [mode, setMode] = useState(editModes.EDIT);
  const [recoverPopup, toggleRecoverPopup] = useState(false);
  const { t } = useTranslate();

  usePrintMode(mode);

  //DUMMY code, remove
  useEffect(() => {
    const numCardsToStart = 1;
    checkSaved();
    for (let i = 0; i < numCardsToStart; i++) {
      cardsDispatch({ type: cardActionTypes.ADD_CARD });
    }
  }, []);

  //prob remove
  const addCard = async () => {
    //keep the await! it is necessary
    await cardsDispatch({ type: cardActionTypes.ADD_CARD });
    let el = document.getElementsByClassName("gameCardsList")[0];
    el.scrollTo(0, el.scrollHeight);
  };
  const deleteCard = (cardId) =>
    cardsDispatch({ type: cardActionTypes.DELETE_CARD, cardId });
  const editCard = (cardId, changes) =>
    cardsDispatch({ type: cardActionTypes.EDIT_CARD, cardId, changes });

  const save = async () => {
    let ls = { ...cardsState, gameTitle };
    let x = JSON.stringify(ls);
    await window.localStorage.setItem(`${gameInfo.name}Items`, x);
  };

  const recover = (recover) => {
    if (recover) {
      //set cards to ls
      try {
        let lsItems = JSON.parse(
          window.localStorage.getItem(`${gameInfo.name}Items`)
        );
        cardsDispatch({ type: cardActionTypes.RECOVER_CARDS, lsItems });
        setGameTitle(lsItems.gameTitle);
        // useDesignSidebar(lsItems.frame, colorPickerJSX)
      } catch (err) {
        console.log("error getting ls", err);
      }
    }
    window.localStorage.setItem(`${gameInfo.name}Items`, "");
    toggleRecoverPopup(false);
  };

  const checkSaved = () => {
    let lsItems;
    try {
      lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      );
      let cards = Object.keys(lsItems)[0];
      if (lsItems[cards].length > 0) {
        toggleRecoverPopup(true);
      }
    } catch (err) {
      console.log("No saved game");
    }
    // return lsItems.length > 0 ? lsItems : null
  };
  const onCardDragEnd = (result) => {
    //if dropped on list move the card
    if (result.destination) {
      cardsDispatch({
        type: cardActionTypes.MOVE_CARD,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
      });
    }
  };
  const { cards } = cardsState;

  let returnValue;
  switch (mode) {
    case editModes.VIEW:
    case editModes.PRINT: {
      returnValue = (
        <div>
          <div className="printTitleContainer">
            {mode === editModes.PRINT && (
              <div
                className="printIconContainer"
                title="print again"
                onClick={(e) => {
                  window.print();
                }}
              >
                <FontAwesomeIcon icon={"print"} />
              </div>
            )}
            <div className="printTitle">
              {gameTitle.length > 0 && gameTitle + " - "}{" "}
              {t(gameTypes[gameInfo.name].value)}
            </div>
          </div>
          <br />
          <div className="printCardsContainer">
            {" "}
            {cards.map((card, index) => (
              <div
                key={card.id}
                style={{ breakInside: "avoid", display: "inline" }}
              >
                {" "}
                <GameCard
                  i={index}
                  colors={colors}
                  key={card.id}
                  cardContent={card}
                  logo={
                    logo ? (
                      <img src={logo} alt="logo" className="logo" />
                    ) : (
                      <div className="logo" />
                    )
                  }
                  frame={frame}
                  styles={
                    {
                      // padding: `${1.7}vw ${1}vw`,
                      // background: 'hotpink'
                    }
                  }
                  mode={mode}
                  vector={vector}
                />
                {/* <br /> */}
              </div>
            ))}
          </div>
        </div>
      );
      break;
    }
    case editModes.EDIT:
    case editModes.DESIGN:
    default: {
      returnValue = (
        <>
          {/* sidebar has list of cards which link to the card and a button to add cards */}
          <div className="gameCardsSidebar">
            {/* color picker */}
            {/* {colorPickerJSX} */}
            <h5 className="header sidebarChild">{t("cards")}</h5>

            {/* links to and sidebar repsesntations of cards. can be dragged to reorder cards,
                        possible TODO make it so numbers on cards in list  stay sma and dont change when reordered*/}
            <DragDropContext onDragEnd={onCardDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className={`dragContainer ${
                      !!snapshot.draggingFromThisWith ? "dragHappening" : ""
                    }`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {cards.map((card, index) => (
                      <Draggable
                        key={card.id}
                        draggableId={card.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <a
                            href={`#anchor${card.id}`}
                            className="cardLink cardsControl sidebarChild"
                            key={card.id}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <img src="icon/gameCards.svg" alt="" />
                            <span className="text">
                              {t("card")}-{/* {card.id < 10 ? "0" : ""} */}
                              {/* {card.id} */}
                              {index < 10 ? "0" : ""}
                              {index + 1}
                            </span>
                            <span className="icon">=</span>
                          </a>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* button to add a cards */}
            {/* <div className="addCardBtn cardsControl sidebarChild" onClick={addCard}>
                            <img src="/icon/lightBlueGameCards.svg" alt="card logo" />
                            <span className="text">Add a new card</span>
                            <span className="icon">+</span>
                        </div> */}
          </div>
          {/* list of game cards */}
          <div className="gameCardsList">
            <input
              onBlur={(e) => setGameTitle(e.target.value)}
              defaultValue={gameTitle.length > 0 ? gameTitle : ""}
              className="titleInput"
              maxLength={20}
              placeholder={gameTitle.length > 0 ? "" : t("enterTitleHere")}
            />
            <div className={cards.length > 0 ? "" : " emptyContainer"}>
              {" "}
              {cards.length > 0 ? (
                cards.map((card, index) => (
                  <GameCard
                    i={index}
                    colors={colors}
                    key={card.id}
                    cardContent={card}
                    logo={logoJSX}
                    setContent={editCard.bind(this, card.id)}
                    handleDeleteCard={deleteCard.bind(this, card.id)}
                    frame={frame}
                    colorPicker={colorPickerJSX}
                    mode={mode}
                    vector={vector}
                  />
                ))
              ) : (
                <div>
                  {t("click")} <br />+ <br />
                  {t("toAddCards")}
                </div>
              )}
              {recoverPopup && (
                <RecoverPopup
                  recover={recover}
                  close={() => toggleRecoverPopup(false)}
                  name={gameInfo.name}
                />
              )}
              {mode === editModes.EDIT ? (
                <div className="addCategoryBtn" onClick={addCard}>
                  {/* <img src="/icon/lightBlueGameCards.svg" alt="card logo" /> */}
                  {/* <span className="text">Add a new card</span> */}
                  <span className="plusSign">+</span>
                </div>
              ) : null}
            </div>
          </div>
          {mode === editModes.DESIGN && designSidebarJSX}
        </>
      );
      break;
    }
  }
  //color picker
  return (
    // <div className={`${classes} ${mode.name} gameCardsMain`} style={{ color }}>
    <GamePage mode={mode} setMode={setMode} gameInfo={gameInfo} save={save}>
      <div
        className={`gamePageMain ${mode.name} ${
          mode.name === "print" ? "" : "gameCardsMain"
        } `}
      >
        {returnValue}
      </div>
    </GamePage>
  );
}

export default GameCards;

function GameCard({
  cardContent: { subtext, main, color, id },
  setContent,
  handleDeleteCard,
  logo,
  frame,
  i,
  styles,
  mode,
  vector,
}) {
  const [imgOptionsOpen, imgOptionsOpenChange] = useState(false);
  const [pixabayOpen, pixabayOpenChange] = useState(true); //shalva

  //whe subtext changed
  const handleSubtextChanged = (event) => {
    const { value } = event.target;
    setContent({ subtext: value });
  };
  const handleColorChanged = (color) => {
    setContent({ color });
  };
  const cancelImgChoice = (tf) => {
    toggleImgOptionsAppearance();
    tf && handleMainChanged("");
  };
  //when image/main changed (at least for now should be image)
  const handleMainChanged = (image) => {
    //shalva
    if (typeof image === "object") {
      setContent({
        main: { type: "image", value: URL.createObjectURL(image) },
      });
    } else if (typeof image === "string" && image.length > 0) {
      //from gallery
      setContent({ main: { type: "image", value: image } });
    } else {
      setContent({ main: { type: "", value: "" } });
    }
    toggleImgOptionsAppearance();
  };
  //call the following when you click on "add image" and when you click "cancel"
  const toggleImgOptionsAppearance = () => {
    imgOptionsOpenChange(!imgOptionsOpen);
  };
  const { t } = useTranslate();

  //when setContent passed, in edit(edit or design) mode. othermise in view mode(print or view)
  return (
    <div className="gameCard" style={styles}>
      {/* {colorPicker} */}
      {(mode === editModes.EDIT || mode === editModes.DESIGN) && (
        <SingleColorPicker changeColor={handleColorChanged} id={id} />
      )}
      <div className="gameCardTop">
        {mode === editModes.PRINT ? null : (
          <span className="anchor" id={`anchor${id}`}>
            {/* Card-{id < 10 ? "0" : ""} */}
            {/* {id} */}
            {t("card")}-{i + 1 < 10 ? "0" : ""}
            {i + 1}
          </span>
        )}
        {/* only display delete icon if delete function passeed */}
        {handleDeleteCard && (
          <FontAwesomeIcon
            icon={["far", "trash-alt"]}
            className="deleteIcon"
            onClick={handleDeleteCard}
          />
        )}
      </div>

      <div
        className={`gameCardContent ${frame ? "hasFrame" : ""} ${
          setContent ? "edit" : "view"
        } `}
        // style={{ backgroundImage: `url(${frame})` }}
      >
        {frame && <img className="frame" src={frame} alt="frame" />}
        {vector && <img className="vector" src={vector} alt="vector" />}
        {/* {vector && <div className="vector" style={{ backgroundImage: `url(${vector})` }}></div>} */}

        {/*  logo at top right. logo state is shared between all cards. passed as jsx that is rendered byy card */}
        <div className="logoContainer">
          <img src="/icon/logoNewNoWording.png" alt="logo" />
          {/* {logo} */}
        </div>
        {/* main content (image in center). prob change className to more descriptive */}
        {/* needs subtextEmpty/empty class because can only select siblings after in css */}
        <label
          onClick={imgOptionsOpenChange}
          className={`main ${!main.type ? "empty" : ""} ${
            !subtext ? "subtextEmpty" : ""
          }`}
        >
          {/* if main type is empty string display camera img other display selected image */}
          {main.type ? (
            <img src={main.value} alt={main.value} />
          ) : (
            <div className="cameraIconContainer">
              {/* <FontAwesomeIcon icon="camera" className="cameraIcon" /> */}
              <img src="/icon/web.svg" alt="add" className="addImgIcon" />
              <div>{t("addImage")}</div>
            </div>
          )}
        </label>
        {/* {imgOptionsOpen && <div className="dark-bg" />} */}
        {imgOptionsOpen && (
          <ImagesPopup
            open={pixabayOpen}
            onPopupClose={() => pixabayOpenChange(false)}
            onPixChange={(val) => handleMainChanged(val)}
            setContent={setContent}
            cancelImgChoice={cancelImgChoice}
            onImgChange={handleMainChanged}
          />
        )}

        {/*  text input at bottom */}
        <div
          className={`subtext ${!subtext ? "empty" : ""}`}
          style={{ color: color }}
        >
          {setContent ? (
            <label style={{ color: color, borderColor: color }}>
              <textarea
                style={{ resize: "none" }}
                value={subtext}
                name="subtext"
                placeholder={!!setContent ? t("addYourText") : undefined}
                onChange={handleSubtextChanged}
                disabled={!setContent}
                maxLength="50"
                rows="2"
              />
            </label>
          ) : (
            //in view mode use paragraph so multi lines possible
            <p
              style={{
                fontSize: `${
                  !main.type
                    ? 12 / (1 + ((subtext.length - 1) * 2) / 3) + 1.5
                    : undefined
                }rem`,
              }}
            >
              {subtext}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

/* <div className="w-100 h-100" style={{borderImageSource: borderImage}}> */

// case "design" : {
//     returnValue = (<>
//         <ViewMode/>
//         {[...Array(8)].map((el, index) => `/frames/frame${index}.svg`).map((el, index) => (
//             <div onClick={() => setBorderImage(el)} key={index}>{el}</div>
//         ))}
//     </>)
//     break;
// }

//  <div className="w-100 h-100" style={{ backgroundImage: `url(${borderImage})` }}>
//  <div className="w-100 h-100" style={{ backgroundImage: `url(/frames/frame1.svg)` }}>
//  <img className="bob" src="frames/frame5.svg" />
//  <div className="w-100 h-100">
// function createCard(cardNumber, mainType = "", mainValue = "", subtext = "") {
//     return {
//         main: {
//             type: mainType,
//             value: mainValue
//         },
//         subtext: subtext,
//         //exists because drag and drop requires non changing key/id.
//         id: cardNumber
//     };
// }

import "../../css/DesignSidebar.scss";
import React, { useState } from "react";
import { useTranslate } from "../../translation/GPi18n";

function DesignSidebar(props) {
  //do a better list of them
  const framesList = [...Array(5)].map(
    (el, index) => `/frames/frame${index + 1}.svg`
  );
  const { t } = useTranslate();

  return (
    <div className="designSidebar">
      {props.shouldShowFrames && (
        <div className="frame-container">
          <p className="header">{t("frames")}</p>
          <div className="framesList">
            <div onClick={() => props.setFrame("")}>
              <div className="noFrame">{t("noFrame")}</div>
            </div>
            {framesList.map((frameUrl) => (
              <div onClick={() => props.setFrame(frameUrl)} key={frameUrl}>
                <img className="frameImg" src={frameUrl} alt="frame" />
              </div>
            ))}
          </div>
        </div>
      )}
      {/* {props.shouldShowFrames && (
                <div className="frame-container">
                    <p className="header">Vectors</p>
                    <div className="framesList">
                        <div onClick={() => props.setVector("")}>
                            <div className="noFrame">none</div>
                        </div>
                        {vectorsList.map(vectorUrl => (
                            <div onClick={() => props.setVector(vectorUrl)} key={vectorUrl}>
                                <img className="vectorImg" src={vectorUrl} alt="frame" />
                            </div>
                        ))}
                    </div>
                </div>
            )} */}
    </div>
  );
}
//create a hook the deals with updating state and returns the current frame and the sidebar.
// function useDesignSidebar(startingFrame = "") {
//     const [frame, setFrame] = useState(startingFrame);
//     const designSidebarJSX = <DesignSidebar setFrame={setFrame} />;
//     return [frame, designSidebarJSX];
// }
//version with colorPickers passed. Dummy
//shouldShowFrames is dummy (dominoes)
function useDesignSidebar(
  startingFrame = "",
  colorPickers,
  shouldShowFrames = true,
  startingVector = "",
  shouldShowVectors = true
) {
  const [frame, setFrame] = useState(startingFrame);
  const [vector, setVector] = useState(startingVector);
  const designSidebarJSX = (
    <DesignSidebar
      setFrame={setFrame}
      shouldShowFrames={shouldShowFrames}
      setVector={setVector}
      shouldShowVectors={shouldShowVectors}
      colorPickers={colorPickers}
    />
  );
  return [{ frame, vector }, designSidebarJSX];
}
export default useDesignSidebar;

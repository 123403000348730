/**
 * @typedef {Object} Mode
 * @property {string} name
 * @property {string} icon - name of FontAwesomeIcon use for display of mode.
 */

/**
 * enum of modes that gameBulders can be. includes name and FontAwesomeIcon to be used for display of mode.
 * @readonly
 * @enum {Mode}
 */
const editModes = {
    EDIT: { name: "edit", icon: "pencil-alt" },
    DESIGN: { name: "design", icon: "magic" },
    VIEW: { name: "view", icon: "eye" },
    PRINT: { name: "print", icon: "print" }
};
export default editModes;

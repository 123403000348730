import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/Logo.scss";
// import ImageInput from "./ImageInput";

/**
 * hook to create and manage a logoComponent.
 * @param {string} [startingState]
 * @returns {[string, logoJSX]} a two long array with 1. the url of the current logo 2. the jsx of the logo component.the logo component is an image input that display the current logo.
 */
//probably remove default for logo
// function useLogo(startingState = "/icon/flat.svg") {
//     const [logo, setLogo] = useState(startingState);
//     const logoJSX = <Logo logo={logo} setLogo={setLogo} defaultLogo="/icon/flat.svg" />;
//     //for now logoComponent is when logo is changeable. logo is a url for when the logo should be only viewab;e
//     //probably change in future
//     return [logo, logoJSX];
// }

const Logo = ({ logo, setLogo, defaultLogo }) => {
    return (
        <label className={`logo ${!logo ? "noLogo" : ""}`}>
            {/* iff there is a logo display it. otherwise display and empty div */}
            {logo ? (
                <img src={logo} alt="logo" />
            ) : (
                <div>
                    {/* <FontAwesomeIcon icon="camera" className="icon" /> */}
                    <img src="/icon/web.svg" alt="add" />
                    <div>Add your logo here </div>
                </div>
            )}
            {/* <ImageInput onChange={file => setLogo(file ? URL.createObjectURL(file) : defaultLogo)} /> */}
        </label>
    );
};

//probably remove default for logo
function useLogo(startingState = "/icon/logoNew.png") {
    // reid: "https://clutchpoints.com/wp-content/uploads/2019/01/Chiefs-news-Andy-Reid-share-message-to-team-after-loss-to-Patriots.jpg"
    const [logo, setLogo] = useState(startingState);
    const logoJsx = <Logo logo={logo} setLogo={setLogo} defaultLogo="/icon/logoNew.png" />;
    //for now logoComponent is when logo is changeable. logo is a url for when the logo should be only viewab;e
    //probably change in future
    return [logo, logoJsx];
}

export default useLogo;

import React from "react";
import "../../css/ColorPicker.scss";

function useSingleColorPicker(props) {

    let defaultColors = ["#66DACB", "#FC0B7A", "#0B57FC", "#0C1731"]
    // console.log("props:", props)

    return (
        <div className="colorPickers">
            <label className="colorPickerContainer">
                <input type="color" onChange={e => props.changeColor(e.target.value, props.id)} id="cPicker" />
                <label htmlFor="cPicker"><img src="/icon/colorCircle.svg" alt="color circle" /></label>
            </label>
            {defaultColors.map(color => {
                return <div id={color} style={{ backgroundColor: color }} key={color} onClick={e => props.changeColor(color, props.id)} />
            })}
        </div>
    );
}

export default useSingleColorPicker;

import { Link } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import editModes from "../editModes";

/*sidebar is passed: 
    mode: the current editMode
    setMode: a function to set the mode
    editMode: an array of objects with the modes. editModes
*/
//sidebar displays the editModes as a group of custom styled radio button and uses setMode when one is clicked
//mode objects are of the for {name: "", icon: ""}]
//name is what its sets the mode to and icon is used to dispaly
const Sidebar = ({ mode, setMode, save }) => {
    return (
        <div className="sidebar">
            <label>
                <Link to="/" className="goToHomeBtn sidebarIcon" title="back to homepage">
                    <FontAwesomeIcon icon={"home"} />
                </Link>
            </label>
            {Object.values(editModes).map(editMode => {
                const checked = editMode === mode;
                return (
                    <label
                        className={`sidebarIcon ${checked ? "checked" : ""}`}
                        key={editMode.name}
                        title={editMode.name}
                    >
                        <input
                            className="sidebarIconRadio"
                            checked={checked}
                            onChange={() => setMode(editMode)}
                            type="radio"
                        />
                        <FontAwesomeIcon icon={editMode.icon} />
                    </label>
                );
            })}

            {/* <label title="save" className={`sidebarIcon saveBtn`} onClick={() => save()} >
                <FontAwesomeIcon icon={["far", "save"]} />
            </label> */}
        </div>
    );
};

export default Sidebar;

// ["edit", <img src="/icons/edit.svg" alt="edit icon"/>],
// ["design", <img src="/icons/wand.svg" alt="design icon"/>],
// ["view", <img src="/icons/eye.svg" alt="view icon"/>],
// ["print",  <FontAwesomeIcon icon="print"/>]

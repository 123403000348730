import React from "react";
//gets passed an input to display
//if type is image uses URL.createObjectURL to set an image
//otherwise displays it as text.
//exists to display category inputs which can be either text or input (and which have to be displayed all over)
const TextOrImageDisplay = ({ element, ...rest }) => {
    // if (content.type === "image") console.log(content.value);
    return element.type === "image" ? (
        <img src={element.value} alt={element.value.name} {...rest} />
    ) : (
        <span {...rest}>{element.value || ""}</span>
    );
};
export default TextOrImageDisplay;

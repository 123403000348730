import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategoriesBuilder from "../CategoriesBuilder/CategoriesBuilder";
import "../../css/GoFish.scss";
import DecoratableCard from "../Assorted/DecoratableCard";
import useLogo from "../Assorted/useLogo";
import RecoverPopup from "../Assorted/recoverPopup";
import TextOrImageDisplay from "../Assorted/TextOrImageDisplay";
import useDesignSidebar from "../Assorted/useDesignSidebar";
import useColorPicker from "../Assorted/useColorPicker";
import editModes from "../editModes";
import GamePage from "../GamePage/GamePage";
import usePrintMode from "../Assorted/usePrintMode";
import { useTranslate } from "../../translation/GPi18n";

// import { LanguageContext } from "../Assorted/LanguageChange";

//DUMMY CODE REMOVE
function createDummyCategories(amount) {
  const categories = [];
  for (let i = 0; i < amount; i++) {
    const category = {
      key: Math.random(),
      name: "Example",
      inputs: [],
      pHolder: `${i}  - Enter title`,
    };
    for (let j = 0; j < 4; j++) {
      category.inputs.push({ type: "text", value: i + "-" + j });
    }
    categories.push(category);
  }
  return categories;
}

const defaultColors = {
  cardTitle: "#0b57fc",
  cardContent: "#66dacb",
  restOfCategory: "#93b5ff",
};

function GoFish({ gameInfo }) {
  const [logo, logoJSX] = useLogo();
  //DUMMY. here for making development easier. starting state should be []
  const [categories, setCategories] = useState(createDummyCategories(0));
  const [colors, colorPickerJSX] = useColorPicker(defaultColors);
  //second argument is DUMMY for COLOR PICKER
  const [frame, designSidebarJSX] = useDesignSidebar("", colorPickerJSX);
  const [recoverPopup, toggleRecoverPopup] = useState(false);
  const [gameTitle, setGameTitle] = useState("");
  //returnValue is set to different jsx depending on mode.
  //done as setting variable in switch, then returning it (instead of directly returning it), so can be given container div with some classes
  const [mode, setMode] = useState(editModes.EDIT);
  usePrintMode(mode);
  // const { language } = useContext(LanguageContext);
  const { t } = useTranslate();

  useEffect(() => {
    checkSaved();
  }, []);

  const save = async () => {
    let lsItems = {
      categories,
      gameTitle,
    };
    let x = JSON.stringify(lsItems);
    await window.localStorage.setItem(`${gameInfo.name}Items`, x);
  };

  const checkSaved = () => {
    let lsItems;
    try {
      lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      );
      let cards = Object.keys(lsItems)[0];
      if (lsItems[cards].length > 0) {
        toggleRecoverPopup(true);
      }
    } catch (err) {
      console.log("No saved game");
    }
  };

  const recover = (recover) => {
    if (recover) {
      //set cards to ls
      let lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      );
      setCategories(lsItems.categories);
      setGameTitle(lsItems.gameTitle);
    }
    //remove ls
    window.localStorage.setItem(`${gameInfo.name}Items`, "");
    toggleRecoverPopup(false);
  };

  let returnValue;
  switch (mode) {
    case editModes.VIEW:
    case editModes.PRINT: {
      //possibly TODO: filter out all categories for which alll inputs not given or all empty inputs
      //displays the categorie with each displayed as a row of cards/div with array of its inputs as GoFishCards
      returnValue = (
        <div className="">
          <div className="printTitleContainer">
            {mode === editModes.PRINT && (
              <div
                className="printIconContainer"
                title="print again"
                onClick={(e) => {
                  window.print();
                }}
              >
                <FontAwesomeIcon icon={"print"} />
              </div>
            )}
            <div className="printTitle">
              {gameTitle.length > 0 && gameTitle + " - "} {gameInfo.name}
            </div>
          </div>
          <br />
          <div className="goFishCategory">
            {categories.map((category, index) => {
              return (
                // {/* passing category and categoryIndex to GoFishCard and not the input because GoFishCard renders whole category*/}
                // {/* GoFishCard component currently at bottom of this file*/}
                category.inputs.map((input, index) => (
                  <GoFishCard
                    printMode={false}
                    colors={colors}
                    key={index}
                    category={category}
                    logo={logo}
                    categoryIndex={index}
                    frame={frame}
                  />
                ))
              );
            })}
          </div>
        </div>
      );
      break;
    }
    //really edit mode but as only view and edit done, written as default
    case editModes.EDIT:
    case editModes.DESIGN:
    default: {
      returnValue = (
        <>
          {recoverPopup && (
            <RecoverPopup
              recover={recover}
              close={() => toggleRecoverPopup(false)}
              name={gameInfo.name}
            />
          )}
          {/* the  component that lets the user create categories and input stuff*/}
          <CategoriesBuilder
            // categorySize={{ size: 4, isVariable: false }}
            variableSizeCategories={false}
            categorySize={4}
            setCategories={setCategories}
            categories={categories}
          />
          <div className="goFishCardFrame">
            <input
              onBlur={(e) => setGameTitle(e.target.value)}
              defaultValue={gameTitle.length > 0 ? gameTitle : ""}
              className="titleInput"
              maxLength={20}
              placeholder={gameTitle.length > 0 ? null : t("enterTitleHere")}
            />
            {/* color picker */}
            {/* {colorPickerJSX} */}
            {/* card with its content empty that is displayed on right (or on top in thin layout) */}
            <DecoratableCard
              className={frame ? "withFrame" : ""}
              frame={frame}
              logo={logoJSX}
              title={
                <span
                  className="goFishTitle"
                  style={{ color: colors.cardTitle }}
                >
                  {categories && categories[0] && categories[0].name.length > 0
                    ? categories[0].name
                    : t("categoryName")}
                </span>
              }
              content={
                categories && categories[0] ? (
                  categories[0].inputs.map((input, index) => {
                    return (
                      <div
                        key={index}
                        className={index === 0 ? "first-option" : ""}
                      >
                        {input.value.length > 0
                          ? input.value
                          : index === 0
                          ? t("myCard")
                          : t("match") + " " + index}
                      </div>
                    );
                  })
                ) : (
                  <div className="demoCard">
                    <div className="first-option">{t("myCard")}</div>
                    <div>{t("match") + " "} 1</div>
                    <div>{t("match") + " "} 2</div>
                    <div>{t("match") + " "} 3</div>
                  </div>
                )
              }
            />
          </div>
          {mode === editModes.DESIGN && designSidebarJSX}
        </>
      );
    }
  }
  return (
    //color picker
    // <div className={`${classes} goFishMain ${mode.name}`} style={{ color }}>
    <GamePage mode={mode} setMode={setMode} gameInfo={gameInfo} save={save}>
      <div className={`gamePageMain goFishMain ${mode.name}`}>
        {returnValue}
      </div>
    </GamePage>
  );
}

export default GoFish;

//exists as component in this file instead of just being called directly or being put in another file because:
//in this file instead of in separate file because only used by this component (and i dont feel like moving it)
//in component despite only called in 1 place because if not separated out the code becomes very deeply nested, unwieldy and ugly
//used in view mode
const GoFishCard = ({
  printMode,
  category,
  categoryIndex,
  logo,
  frame,
  colors,
}) => {
  //copy inputs so they can be mutated
  const inputs = [...category.inputs];
  //remove the cards main content from inputs and call it cardContent
  const cardContent = inputs.splice(categoryIndex, 1)[0];
  return (
    // return a Decoratable card
    // content of card is cardContent(in big print) with the categories other inputs displayed below (in small print. see scss file for details)
    <DecoratableCard
      className={`goFishCard ${frame ? "withFrame" : ""}`}
      logo={<img src={logo} alt="logo" />}
      title={
        printMode ? null : (
          <span className="goFishTitle" style={{ color: colors.cardTitle }}>
            {category.name}
          </span>
        )
      }
      frame={frame}
      content={
        <div className="goFishCardContent">
          {/* display the main content.*/}
          <div className="cardContent" style={{ color: colors.cardContent }}>
            <TextOrImageDisplay element={cardContent} />
          </div>
          {/* display the other inputs. note: as cardContent was spliced from it it isnt in the input array anymore*/}
          <div className="restOfCategoryContainer">
            {" "}
            {inputs.map((input, index) => (
              <div
                className="restOfCategory"
                key={index}
                style={{ color: colors.restOfCategory }}
              >
                <TextOrImageDisplay element={input} />
              </div>
            ))}
          </div>
        </div>
      }
    />
  );
};

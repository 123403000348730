import React, { useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImagesPopup from "../Assorted/imagesPopup";
import { useTranslate } from "../../translation/GPi18n";

/*CategoryBuilderInput: 
    gets an input object and lets the user set it.
    is rendered by CategoryBuilder
    returns the set inputObject
        has three states based on the current type of the input object
        "text": shows a basic text field
        "image": goes to file and select animage which is displayed
        anythingElse(typically: ""): shows a box with icons that change type to "text" and "image"
            the one to change to image opens a file picker to let the user select an image
*/

function CategoryBuilderInput({ input, setInput, deleteInput }) {
  // const [inputType, setInputType] = useState("")
  const [imgOptionsOpen, imgOptionsOpenChange] = useState(false);
  const [pixabayOpen, pixabayOpenChange] = useState(true); //shalva
  const { t } = useTranslate();

  const clearInput = () => {
    setInput({ type: "", value: "" });
  };
  const cancelImgChoice = (tf) => {
    toggleImgOptionsAppearance();
    if (tf) {
      handleImageInput("");
      setInput({ type: "" });
    }
  };
  const toggleImgOptionsAppearance = () => {
    imgOptionsOpenChange(!imgOptionsOpen);
  };
  const handleTextInput = (event) => {
    setInput({ value: event.target.value, type: "text" });
  };

  const handleImageInput = (image, filter) => {
    //image is imageURL
    if (image) {
      if (typeof image === "object") {
        setInput({
          value: URL.createObjectURL(image),
          name: image.name,
          type: "image",
        });
      } else if (typeof image === "string" && image.length > 0) {
        setInput({ type: "image", name: filter, value: image });
      } else {
        setInput({ type: "", value: "", name: "" });
      }
    } else {
      clearInput();
    }
    toggleImgOptionsAppearance();
  };
  const initialImgPopupOpen = () => {
    toggleImgOptionsAppearance();
    setInput({ type: "image", value: "" });
  };
  let returnValue;
  switch (input.type) {
    case "image": {
      returnValue = (
        <div className="categoryInput categoryInputImageInput">
          <div className="imageInputTopRow">
            <img className="iconImage" src="/icon/pic.svg" alt="" />
            <span className="imageName">{input.name}</span>
            <div className="clearInputBtn" onClick={clearInput}>
              &times;
            </div>
          </div>
          <label>
            {input.value && (
              <img
                src={input.value}
                className="inputImage"
                alt="input"
                onClick={toggleImgOptionsAppearance}
              />
            )}
            {/* <ImageInput onChange={handleImageInput} /> */}
            {imgOptionsOpen && (
              <ImagesPopup
                open={pixabayOpen}
                onPopupClose={() => pixabayOpenChange(false)}
                onPixChange={(val, filter) => handleImageInput(val, filter)}
                setContent={true}
                cancelImgChoice={cancelImgChoice}
                onImgChange={handleImageInput}
              />
            )}
          </label>
          {/* <FontAwesomeIcon icon="ellipsis-h" className="ellipsisIcon" onClick={clearInput} /> */}
          {/* <div className="deleteInputBtn" onClick={clearInput}>&times;</div> */}
        </div>
      );
      break;
    }
    case "text": {
      returnValue = (
        <div className="categoryInput categoryInputTextInput">
          <input type="text" value={input.value} onChange={handleTextInput} />
          {/* <FontAwesomeIcon icon="ellipsis-h" className="ellipsisIcon" onClick={clearInput} /> */}
          <div className="clearInputBtn" onClick={clearInput}>
            &times;
          </div>
        </div>
      );
      break;
    }
    // using imgs instead of font awesome because fontawesome doesnt have an icon that fits for the "/icons/font/svg"
    default: {
      returnValue = (
        // <div className="clearInputBtn" onClick={clearInput}>
        //         &times;
        // </div>
        <div className="categoryInput categoryInputNoInput">
          <input
            className="default"
            type="text"
            value={input.value}
            onChange={handleTextInput}
            placeholder={t("addTextOrImage")}
          />
          {/* <span className="text">Add a text or an image</span> */}
          <label
            className="categoryInputIcon"
            id="image"
            onClick={initialImgPopupOpen}
          >
            <img src="/icon/camera.svg" alt="camera icon" />
            {/* <ImageInput onChange={handleImageInput} /> */}
            {/* i{" "} */}
            {imgOptionsOpen && (
              <ImagesPopup
                open={pixabayOpen}
                onPopupClose={() => pixabayOpenChange(false)}
                onPixChange={(val) => handleImageInput(val)}
                setContent={true}
                cancelImgChoice={cancelImgChoice}
                onImgChange={handleImageInput}
              />
            )}
          </label>

          <div
            className="categoryInputIcon"
            onClick={() => setInput({ type: "text", value: "" })}
          >
            <img src="/icon/font.svg" alt="upper and lowercase A" />
          </div>
          {/* if dont have deleteInput then not deletable and dont display deleteBtn */}
          {deleteInput && (
            <div className="deleteInputBtn" onClick={deleteInput}>
              &times;
            </div>
          )}
        </div>
      );
    }
  }

  return returnValue;
}

export default CategoryBuilderInput;

// const [input, setInput] = useState("");

//get a url to display the image and dispose of it when a new input comes.
//as youre setting a state from inside the effect and you want to avoid an infinite loop
//only run it when input !have an objectUrl prop
// useEffect(() => {
//     if (input.file && input.file instanceof File && !input.objectUrl ) {
//         setInput({...input, objectUrl: URL.createObjectURL(input.file) })
//         return () => {
//             console.log(2);
//             URL.revokeObjectURL(input.objectUrl);
//        }
//     }
// }, [input])

import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategoriesBuilder from "../CategoriesBuilder/CategoriesBuilder";
import "../../css/Dominoes.scss";
import TextOrImageDisplay from "../Assorted/TextOrImageDisplay";
import RecoverPopup from "../Assorted/recoverPopup";
import useColorPicker from "../Assorted/useColorPicker";
import useDesignSidebar from "../Assorted/useDesignSidebar";
import editModes from "../editModes";
import GamePage from "../GamePage/GamePage";
import usePrintMode from "../Assorted/usePrintMode";
import useLazyMemo from "../Assorted/useLazyMemo";
import { useTranslate } from "../../translation/GPi18n";
import gameTypes from "../../components/gameTypes";

//DUMMY CODE REMOVE
function createDummyCategories(amount, categorySize) {
  const categories = [];
  for (let i = 1; i <= amount; i++) {
    const category = {
      key: Math.random(),
      name: `${"Example"}`,
      inputs: [],
      pHolder: `${i}  - Enter title`,
    };
    for (let j = 0; j < categorySize; j++) {
      category.inputs.push({ type: "text", value: i + "-" + j });
    }
    categories.push(category);
  }
  return categories;
}
const defaultColors = {
  dominoText: "#0b57fc",
};
function Dominoes({ gameInfo }) {
  //DUMMY CODE REMOVE. starting state should be
  const [categories, setCategories] = useState(createDummyCategories(0, 2));
  //useLazyMemo needed so dominoesList isnt recomputed whenever categories changes but doesn tchange unless categories do.
  const [
    getDominoesList,
    regenerateDominoesList,
  ] = useLazyMemo(generateDominoesList, [categories]);
  const [colors, colorPickerJSX] = useColorPicker(defaultColors);
  //DUMMY for COLOR PICKER. might use design sidebar in dominoes but need way to have frames part not show
  // third argument is DUMMY and  says dont show frames
  const [, designSidebarJSX] = useDesignSidebar(
    undefined,
    colorPickerJSX,
    false
  );
  const [gameTitle, setGameTitle] = useState("");
  const [recoverPopup, toggleRecoverPopup] = useState(false);
  //converts categories into the list of dominoes to be built
  //set bottom of component for dominoesList code
  const [mode, setMode] = useState(editModes.EDIT);
  usePrintMode(mode);
  const { t } = useTranslate();

  useEffect(() => {
    checkSaved();
  }, []);

  const save = async () => {
    let lsItems = { categories, gameTitle };
    let x = JSON.stringify(lsItems);
    await window.localStorage.setItem(`${gameInfo.name}Items`, x);
  };

  const checkSaved = () => {
    let lsItems;
    try {
      lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      );
      let cards = Object.keys(lsItems)[0];
      if (lsItems[cards].length > 0) {
        toggleRecoverPopup(true);
      }
    } catch (err) {
      console.log("No saved game");
    }
  };

  const recover = (recover) => {
    if (recover) {
      //set cards to ls
      let lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      );
      setCategories(lsItems.categories);
      setGameTitle(lsItems.gameTitle);
    }
    window.localStorage.setItem(`${gameInfo.name}Items`, "");
    toggleRecoverPopup(false);
  };
  let returnValue;
  switch (mode) {
    case editModes.VIEW:
    case editModes.PRINT: {
      returnValue = (
        <>
          {mode === editModes.VIEW && (
            <button
              onClick={regenerateDominoesList}
              className="btn btn-primary"
            >
              {t("shuffle")}
            </button>
          )}
          {/* container necessary to put shuffle button in */}
          <div className="printTitleContainer">
            {mode === editModes.PRINT && (
              <div
                className="printIconContainer"
                title="print again"
                onClick={(e) => {
                  window.print();
                }}
              >
                <FontAwesomeIcon icon={"print"} />
              </div>
            )}
            <div className="printTitle">
              {gameTitle.length > 0 && gameTitle + " - "}{" "}
              {t(gameTypes[gameInfo.name].value)}
            </div>
          </div>
          <br />
          <div className="dominoesContainer">
            {getDominoesList().map((domino, index) => (
              <Domino
                hasTitle={false}
                leftSide={domino[0]}
                rightSide={domino[1]}
                dominoNumber={index + 1}
                key={index}
                colors={colors}
              />
            ))}
          </div>
        </>
      );
      break;
    }
    case editModes.EDIT:
    case editModes.DESIGN:
    default: {
      returnValue = (
        <>
          {mode === editModes.EDIT && (
            <input
              onBlur={(e) => setGameTitle(e.target.value)}
              defaultValue={gameTitle.length > 0 ? gameTitle : ""}
              maxLength={20}
              className="titleInput"
              placeholder={gameTitle.length > 0 ? "" : t("enterTitleHere")}
            />
          )}
          {recoverPopup && (
            <RecoverPopup
              recover={recover}
              close={() => toggleRecoverPopup(false)}
              name={gameInfo.name}
            />
          )}
          {/* color picker */}
          {/* {colorPickerJsx} */}
          {/* <ColorPicker color={color} setColor={setColor} /> */}
          <CategoriesBuilder
            displayName={false}
            isNameEditable={false}
            // categorySize={{ size: 2, isVariable: true }}
            categorySize={2}
            variableSizeCategories={true}
            addCategoryIconSrc={"/icon/domino.svg"}
            setCategories={setCategories}
            categories={categories}
          />
          {/* DUMMY for COLOR PICKEr */}
          {mode === editModes.DESIGN && designSidebarJSX}
        </>
      );
    }
  }
  //printable on entire because entire thing is to be print in print mode.
  return (
    //color picker
    // <div className={`dominoesMain ${classes} ${mode.name}`} style={{ color }}>
    //     {returnValue}
    // </div>
    <GamePage mode={mode} setMode={setMode} gameInfo={gameInfo} save={save}>
      <div className={`dominoesMain gamePageMain ${mode.name}`}>
        {returnValue}
      </div>
    </GamePage>
  );
}

export default Dominoes;

//component to display a domino.
function Domino({
  hasTitle = true,
  leftSide,
  rightSide,
  dominoNumber,
  className = "",
  colors,
}) {
  const textStyle = { color: colors.dominoText };
  return (
    <div className="domino">
      {hasTitle && (
        <div className="dominoTop">
          Domino-{dominoNumber < 10 ? "0" : ""}
          {dominoNumber}
        </div>
      )}
      <div className={"dominoBody " + className}>
        <div
          className={`dominoHalf ${
            leftSide.value.length > 8 ? "longText" : ""
          }`}
          style={textStyle}
        >
          <TextOrImageDisplay element={leftSide} />
        </div>
        <div className="dominoDivider" />
        <div
          className={`dominoHalf ${
            rightSide.value.length > 8 ? "longText" : ""
          }`}
          style={textStyle}
        >
          <TextOrImageDisplay element={rightSide} />
        </div>
      </div>
    </div>
  );
}

function generateDominoesList(categories) {
  //dominoes consist of two categories with a random input from each category being chosen.
  //each category is on one domino with every other category (including itself)

  //only the inputs of categories is being used so extract them out.
  const categoryInputs = categories.map((category) => category.inputs);
  //create the list of dominoes by create dominoes of categories.
  const categoryDominoes = categoryInputs.flatMap((category1, index1) =>
    categoryInputs.slice(index1).map((category2) => [category1, category2])
  );
  //convert each category in each domino into a random input from the category
  const newDominoesList = categoryDominoes.map((domino) =>
    domino.map(
      (category) => category[Math.floor(Math.random() * category.length)]
    )
  );
  return newDominoesList;
}

import React, {
  useState,
  useReducer,
  useContext,
  createContext,
  useEffect,
} from "react";
import CategoriesBuilder from "../CategoriesBuilder/CategoriesBuilder";
import "../../css/Bingo.scss";
import DecoratableCard from "../Assorted/DecoratableCard";
import useLogo from "../Assorted/useLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDesignSidebar from "../Assorted/useDesignSidebar";
// import TextOrImageDisplay from "../Assorted/TextOrImageDisplay";
import RecoverPopup from "../Assorted/recoverPopup";
import useColorPicker from "../Assorted/useColorPicker";
import useModes from "../Assorted/useModes";
import editModes from "../editModes";
import GamePage from "../GamePage/GamePage";
import usePrintMode from "../Assorted/usePrintMode";
import useLazyMemo from "../Assorted/useLazyMemo";
import { useTranslate } from "../../translation/GPi18n";

//DUMMY CODE REMOVE just creates placeholder data to see how displays
function createDummyCategories(amount, categorySize) {
  const categories = [];
  for (let i = 1; i <= amount; i++) {
    const category = {
      key: Math.random(),
      name: `Example ${i}`,
      inputs: [],
      pHolder: `Note ${i}  - Enter title`,
    };
    for (let j = 0; j < categorySize; j++) {
      category.inputs.push({ type: "text", value: i + "-" + j });
    }
    categories.push(category);
  }
  return categories;
}
const defaultColors = {
  boardTitle: "#0b57fc",
  cellBorder: "#0b57fc",
  cellText: "#0b57fc",
};
const ColorContext = createContext(defaultColors);

function Bingo({ gameInfo }) {
  //DUMMY. here for making development easier. starting state should be []
  const [recoverPopup, toggleRecoverPopup] = useState(false);
  const [categories, setCategories] = useState(createDummyCategories(0, 3));
  //useStatePositiveNumber is at bottom of file and ensures doesnt become negative.
  //change function takes an amount to change by, not a number to set it to. (prob change to just taking increment and decrement)
  const [boardSize, changeBoardSize] = useStatePositiveInteger(4);
  const [numBoards, changeNumBoards] = useStatePositiveInteger(10);
  const [logo, logoJSX] = useLogo();
  const [title, setTitle] = useState("");
  //COLOR PICKER
  const [colors, colorPickerJSX] = useColorPicker(defaultColors);
  const titleStyle = { color: colors.boardTitle };
  //remove Second argument DUMMY COLOR PICKER
  const [frame, designSidebarJSX] = useDesignSidebar(undefined, colorPickerJSX);
  const [mode, handleModeChange, modeChangeConfirmationDialogJSX] = useModes(
    [editModes.PRINT],
    [categories.length >= boardSize ** 2],
    //TEXT TRANSLATE
    `You currently have less categories (${
      categories.length
    }) than the size of a board (${
      boardSize ** 2
    }). Some categories will be used multiple times. Do you want to continue?`,
    editModes.EDIT
  );
  // const { language } = useContext(LanguageContext);
  // console.log(language);
  const { t } = useTranslate();

  usePrintMode(mode);

  useEffect(() => {
    checkSaved();
  }, []);

  const save = async () => {
    let lsItems = {
      categories,
      boardSize,
      numBoards,
      title,
    };
    let x = JSON.stringify(lsItems);
    await window.localStorage.setItem(`${gameInfo.name}Items`, x); //TEXT TRANSLATE
  };

  const checkSaved = () => {
    let lsItems;
    try {
      lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      ); //TEXT TRANSLATE

      let cards = Object.keys(lsItems)[0];
      if (lsItems[cards].length > 0) {
        toggleRecoverPopup(true);
      }
    } catch (err) {
      console.log("No saved game");
    }
  };

  const recover = (recover) => {
    if (recover) {
      //set cards to ls
      let lsItems = JSON.parse(
        window.localStorage.getItem(`${gameInfo.name}Items`)
      );
      setCategories(lsItems.categories);
      let bSize = boardSize;
      let bAmnt = numBoards;
      while (bSize < lsItems.boardSize) {
        changeBoardSize("increment");
        bSize++;
      }
      while (bSize > lsItems.boardSize) {
        changeBoardSize("decrement");
        bSize--;
      }
      while (bAmnt < lsItems.numBoards) {
        changeNumBoards("increment");
        bAmnt++;
      }
      while (bAmnt > lsItems.numBoards) {
        changeNumBoards("decrement");
        bAmnt--;
      }
      // changeNumBoards(useStatePositiveInteger(lsItems.numBoards))
      setTitle(lsItems.title);
    }
    window.localStorage.setItem(`${gameInfo.name}Items`, ""); //TEXT TRANSLATE

    toggleRecoverPopup(false);
  };

  const [getBingoBoardsList] = useLazyMemo(generateBingoBoardsList, [
    categories,
    boardSize,
    numBoards,
  ]);
  //returnValue is set to different jsx depending on mode.
  //done as setting variable in switch, then returning it (instead of directly returning it), so can be given container div with some classes
  let returnValue;
  switch (mode) {
    //in view and print mode show randomized bingo boards. generation done in BingoBoard component. BingoBoard component is (for now) located at bottom of file
    case editModes.VIEW: {
      returnValue = getBingoBoardsList().map((elementsList, index) => (
        <DecoratableCard
          key={index}
          //if theres a frame then put .6 padding for each cell. otherwise 1 rem;
          // style={{ padding: `${frame ? boardSize * 0.6 : 1}rem` }}
          frame={frame}
          logo={<img src={logo} alt="logo" />}
          title={
            // color picker
            <span className="bingoTitle" style={titleStyle}>
              {title}
            </span>
          }
          content={
            <BingoBoard
              elements={elementsList}
              boardSize={boardSize}
              colors={colors}
            />
          }
        />
      ));

      break;
    }
    case editModes.PRINT: {
      //in view mode done. in print mode convert into rows of cards

      /*goal is too put as many bingo boards as possible per row.
       *however bingoboards are variable size depending on frame and boardSize.
       *because csspages suck to avoid page breaks inside bingo boards exact size must be known and-
       *then containers with as any can boards fit in and thats whats printed.*/
      //board sizing: all units vw.
      //for calculations multiply padding and margins by 2 because on both sides
      // const multiplier = 2; //thing all values multiplied by. remove and factor in for final product. for now useful to change size for everything on fly
      // const paddingPerCell = 0.6; //padding on all sides of decoratableCard per boardCell when theres a frame
      // const paddingNoFrame = 0.5; //padding when theres no frame
      // const paddingX = frame ? boardSize * paddingPerCell : paddingNoFrame; //padding on inside of card
      // const contentPadding = 2.25; //padding on sides of board
      // const cardMargin = 1.25; // margin on cards sides
      // const cellSize = 4; //width of bingoCells
      // const pageMargin = 1; // margin on page
      // const boardWidth = ((contentPadding + cardMargin + paddingX) * 2 + boardSize * cellSize) * multiplier; //width of board.
      //if less than 1 board per row then set it to 1;
      // const boardsPerRow = Math.floor((100 - pageMargin * 2) / boardWidth) || 1;

      let currentRow = [];
      const boards = [];
      getBingoBoardsList().forEach((elementsList, index) => {
        currentRow.push(
          <DecoratableCard
            key={index}
            style={
              {
                // padding: `${paddingX * 1.7 * multiplier}vw ${paddingX * multiplier}vw`
              }
            }
            frame={frame}
            logo={<img src={logo} alt="logo" />}
            title={
              // color picker
              <span className="bingoTitle" style={titleStyle}>
                {title}
              </span>
            }
            content={
              <BingoBoard
                elements={elementsList}
                boardSize={boardSize}
                colors={colors}
              />
            }
          />
        );
        //when the currentRow is the size of a row or if on last element, add row to returnValue and resest currentRow
        // if (currentRow.length === boardsPerRow || index === numBoards - 1) {
        boards.push(
          <div className="bingoPrintContainer" key={boards.length}>
            {currentRow}
          </div>
        );
        currentRow = [];
        // }
      });
      returnValue = (
        <>
          <div id="bob" />
          <div className="printTitleContainer">
            {mode === editModes.PRINT && (
              <div
                className="printIconContainer"
                title="print again"
                onClick={(e) => {
                  window.print();
                }}
              >
                <FontAwesomeIcon icon={"print"} />
              </div>
            )}
            <div className="printTitle">
              {title.length > 0 ? title + " - Bingo" : "Bingo"}
            </div>
          </div>
          <br />
          <InputsList categories={categories} />
          <div className="bingoBoardsContainer">{boards}</div>
        </>
      );
      break;
    }

    case editModes.EDIT:
    case editModes.DESIGN:
    default: {
      returnValue = (
        <>
          {recoverPopup && (
            <RecoverPopup
              recover={recover}
              close={() => toggleRecoverPopup(false)}
              name={gameInfo.name}
            />
          )}
          {/* the  component that lets the user create categories and input stuff*/}
          <CategoriesBuilder
            // categorySize={{ size: 2, isVariable: true }}
            variableSizeCategories={true}
            categorySize={2}
            setCategories={setCategories}
            categories={categories}
            isBingo={true}
          />
          {/* empty bingo card that is displayed to the right (top in thin mode) */}
          <div className="bingoCardFrame">
            {/* color picker */}
            {/* {colorPickerJSX} */}
            <DecoratableCard
              logo={logoJSX}
              frame={frame}
              title={
                <input
                  // color picker
                  style={titleStyle}
                  className="bingoNameInput bingoTitle"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={t("enterTitleHere")}
                />
              }
              content={
                <BingoBoard
                  elements={Array(boardSize ** 2).fill({ type: "", value: "" })}
                  boardSize={boardSize}
                />
              }
            />

            {/* controls to determine how many boards to generate (numBoards) and how big the boards should be (boardSize*/}
            <div className="controls">
              {/* code for numBoards and boardSize controls*/}
              {[
                // { name: "Size: ", amount: `${boardSize}x${boardSize}`, changeNumber: changeBoardSize },
                // { name: "Number of boards: ", amount: numBoards, changeNumber: changeNumBoards }
                [
                  `${t("size")}: `,
                  `${boardSize}x${boardSize}`,
                  changeBoardSize,
                ],
                [`${t("numberOfBoards")}: `, numBoards, changeNumBoards],
              ].map(([name, amountText, changeNumber]) => (
                <div className="control" key={name}>
                  <span>{name}</span>
                  <div>
                    <FontAwesomeIcon
                      icon="caret-up"
                      className="controlArrow"
                      onClick={() => changeNumber("increment")}
                    />
                    <span>{amountText}</span>
                    <FontAwesomeIcon
                      icon="caret-down"
                      className="controlArrow"
                      onClick={() => changeNumber("decrement")}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {mode === editModes.DESIGN && designSidebarJSX}
        </>
      );
    }
  }
  // end switch statement
  // color picker
  return (
    //COLOR PICKER
    <GamePage
      mode={mode}
      setMode={handleModeChange}
      gameInfo={gameInfo}
      save={save}
    >
      <ColorContext.Provider value={colors}>
        <div className={`gamePageMain bingoMain ${mode.name}`}>
          {" "}
          {returnValue}
        </div>
      </ColorContext.Provider>
      {modeChangeConfirmationDialogJSX}
    </GamePage>
    //  <div className={`${classes} bingoMain ${mode.name}`} style={{ color }}>
    // <div className={`${classes} bingoMain ${mode.name}`}>{returnValue}</div>
  );
}

export default Bingo;

// generates randomized bingo board
// is given a list of elements and a boardSize and generates a bingo board of that size randomly filled with the elements
function BingoBoard({ elements, boardSize }) {
  const colors = useContext(ColorContext);
  let elementsCopy = [...elements];
  const board = [];
  for (let rowNum = 0; rowNum < boardSize; rowNum++) {
    //initalize a row, put boardSize cells into it
    const row = [];
    for (let columnNum = 0; columnNum < boardSize; columnNum++) {
      //remove an element from elementsCopy, generate cell jsx from it and push it onto the row
      const element = elementsCopy.pop();
      //COLOR PICKER
      const cellJsx = (
        <div
          className="bingoCell"
          key={columnNum}
          style={{ borderColor: colors.cellBorder, color: colors.cellText }}
        >
          {/* writing down instead of using TextOrImageDisplay Component for speed reasons */}
          {/* <TextOrImageDisplay content={element} /> */}
          {element.type === "image" ? (
            <img src={element.value} alt={element.value.name} />
          ) : (
            <span>{element.value || ""}</span>
          )}
        </div>
      );
      row.push(cellJsx);
    }

    board.push(
      <div className="bingoRow" key={rowNum}>
        {row}
      </div>
    );
  }
  return <div className="bingoBoard">{board}</div>;
}

//takes categories list and baordSize.
//returns a list of inputs of length boardSize^2
//each category has as close to an equal of the inputs taken from them as possible
function generateBingoElements(categories, boardSize) {
  const boardElements = [];
  //extract inputs from categories, filter empty ones out, and randomize the inputs order.
  let categoriesCopy = categories.map((category) =>
    arrayShuffle(category.inputs.filter((input) => input.type !== ""))
  );
  while (boardElements.length < boardSize ** 2 && categoriesCopy.length > 0) {
    //filter out all categories that are empty (were emptied in previous iteration) then shuffle them
    categoriesCopy = arrayShuffle(
      categoriesCopy.filter((category) => category.length > 0)
    );
    //take first input(which is random as inputs shuffled at start) out of every category
    //the slice is to keep boardElements from becoming greater than boardSize**2 by
    boardElements.push(
      ...categoriesCopy
        .slice(0, boardSize ** 2 - boardElements.length)
        .map((cat) => cat.pop())
    );
  }
  //pad boardElements to boardSize^2 by putting empty inputs at random spots.
  while (boardElements.length < boardSize ** 2) {
    let randomIndex = Math.floor(Math.random * (boardElements.length + 1));
    boardElements.splice(randomIndex, 0, { type: "", inputs: "" });
  }
  return boardElements;
}

function generateBingoBoardsList(categories, boardSize, numBoards) {
  return [...Array(numBoards)].map((_) =>
    generateBingoElements(categories, boardSize)
  );
}
const useStatePositiveInteger = (startingNumber) => {
  //custom hook that exists for changing the size and number of boards (tbh probablyunneccessary but wrote because i wanted to and its a perfectly cromulent way to do it)
  //self explanatory. increments and decrements. for numBoards and boardSize to keep as positive integers (they dont really make sense non-whole or negative))
  function reducer(stateNumber, action) {
    switch (action) {
      case "increment":
        return stateNumber + 1 <= 50 ? stateNumber + 1 : 50; //shalva here we limit the bingo size
      case "decrement":
        return stateNumber - 1 >= 1 ? stateNumber - 1 : 1; // dont decrement if it would make less than one

      default:
        return stateNumber;
    }
  }
  const [stateNumber, dispatch] = useReducer(reducer, startingNumber);
  return [stateNumber, dispatch];
};

//a few different places in code need it
function arrayShuffle(arr) {
  const arrCopy = [...arr];
  for (let i = arrCopy.length - 1; i > 0; i--) {
    let random = Math.floor(Math.random() * (i + 1));
    [arrCopy[random], arrCopy[i]] = [arrCopy[i], arrCopy[random]];
  }
  return arrCopy;
}

function InputsList({ categories }) {
  const rowSize = 5;
  //flatten categories and filter out empty inputs
  const inputsList = categories.flatMap((category) => {
    category.inputs.filter((input) => input.type !== "");
  });
  const namesList = categories.flatMap((note) => {
    return note.name;
  });

  //split inputsList into chunks of rowSize.
  const splitInputsList = [];
  for (let i = 0; i < inputsList.length; i += rowSize) {
    const row = inputsList.slice(i, i + rowSize);
    //if row length less than rowSize (last row) pad with empty inputs (code put here and not after loop because cleaner)
    while (row.length < rowSize) row.push({ type: "", value: "" });
    splitInputsList.push(row);
  }

  return (
    <div className="inputsList">
      {namesList.map((name, i) => {
        return (
          // <div className="inputsListCell" key={name}>
          <div className="cellContentContainer" key={i}>
            {name}
            {/* <TextOrImageDisplay element={name} key={name} className="cellContent" /> */}
            {/* </div> */}
          </div>
        );
      })}
      {/* {splitInputsList.map((row, index) => (
                <div className="inputsListRow" key={index}>
                    {row.map((input, index) => (
                        // double container for css reasons (get cells square, text centered and images taking up full area)
                        <div className="inputsListCell" key={index}>
                            <div className="cellContentContainer">
                                <TextOrImageDisplay element={input} key={index} className="cellContent" />
                            </div>
                        </div>
                    ))}
                </div>
            ))} */}
    </div>
  );
}
// case editModes.VIEW.name:
// case editModes.PRINT.name: {
//     //show randomized bingo boards. generation done in BingoBoard component. BingoBoard component is (for now) located at bottom of file

//     //categories have no actual meaning/use in bingo boards so disregard and extract the inputs
//     //filter out all empty inputs
//     // const elements = categories.flatMap(category => category.inputs).filter(input => input.type !== "");
//     const elements = categories.flatMap(category => category.inputs);

//     returnValue = [...Array(numBoards)].map((el, index) => (
//         <DecoratableCard
//             key={index}
//             frame={frame}
//             logo={<img src={logo} alt="logo" />}
//             title={<span className="title">{title}</span>}
//             content={<BingoBoard elements={elements} boardSize={boardSize} />}
//         />
//     ));
//     //in view mode done. in print mode convert into rows of cards
//     //amonut of bingo cards per row is dependent on size of bingo board (set to put max per row without overflow)
//     if (mode === "view") break;
//     //14.5vw extra, 2vw margin, multiplier 1.4 rn. look at scss for details
//     const multiplier = 1.4;
//     const boardWidth = (14.5 + boardSize * 4.5) * multiplier;
//     const boardsPerRow = Math.floor(96 / boardWidth);
//     if (boardsPerRow < 1) break;
//     returnValue = returnValue.flatMap((card, index) => {
//         if (index % boardsPerRow !== 0) return [];
//         return (
//             <div className="bingoPrintContainer" key={index}>
//                 {[...Array(boardsPerRow)].map((el, rowIndex) => returnValue[index + rowIndex])}
//             </div>
//         );
//     });
//     break;
// }
// function BingoBoard2({ elements, boardSize }) {
//     /* possibly TODO ("view"): make it so something along the lines of every category has same/similar amounts or has at
//     least one in (assuming less categories than boardcells) (possibly move to BingoBoard) */
//     //TODO make so when empty elements list doesnt try to randomly generate

//     //copy elements and filter out all empty elements
//     const elementsCopy = elements.filter(element => element.type !== "");

//     //pad elementsCopy with empty values if elementsCopy < boardSize**2 (empty values will appear as empty cells)
//     while (elementsCopy.length < boardSize ** 2) {
//         elementsCopy.push({ type: "text", value: "" });
//     }

//     const board = [];
//     for (let rowNum = 0; rowNum < boardSize; rowNum++) {
//         //initalize a row, put boardSize cells into it
//         const row = [];
//         for (let columnNum = 0; columnNum < boardSize; columnNum++) {
//             //remove a random element from elementsCopy
//             const removeIndex = Math.floor(Math.random() * elementsCopy.length);
//             const element = elementsCopy.splice(removeIndex, 1)[0];

//             //generate a cell/generate jsx from element and push it onto the row
//             const cellJsx = (
//                 <div className="bingoCell" key={columnNum}>
//                     {/* <TextOrImageDisplay content={element} /> */}
//                     {element.type === "image" ? (
//                         <img src={element.value} alt={element.value.name} />
//                     ) : (
//                         <span>{element.value || ""}</span>
//                     )}
//                 </div>
//             );
//             row.push(cellJsx);
//         }

//         board.push(
//             <div className="bingoRow" key={rowNum}>
//                 {row}
//             </div>
//         );
//     }
//     return <div className="bingoBoard">{board}</div>;
// }

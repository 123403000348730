import React from "react";
import CategoryBuilderInput from "./CategoryBuilderInput";
import { useTranslate } from "../../translation/GPi18n";

/*
CategoryBuilder: 
    gets:
        name, inputs: the name and the inputs
        setCategoryData: a function that it calls to set changes to the data of the category
        handleDeleteCategory: a function it calls to delete self when the delete button is press

    overall it gets the inputs and name. it registers any changes puts them in the right form and calls setCategoryData
    setCategoryData is called with an updateObject which the parent merges with the data of that category
*/

const CategoryBuilder = ({
  displayName = true,
  isNameEditable = true,
  setCategoryData,
  handleDeleteCategory,
  category: { name, pHolder = null, inputs },
  isSizeVariable,
}) => {
  const handleNameChange = (event) => {
    setCategoryData({ name: event.target.value });
  };

  const handleInputsChange = ({ input, index }) => {
    const inputsCopy = [...inputs];
    inputsCopy[index] = input;
    setCategoryData({ inputs: inputsCopy });
  };
  const addInput = () => {
    setCategoryData({ inputs: [...inputs, { type: "", value: "" }] });
  };
  const { t } = useTranslate();

  const deleteInput = (index) => {
    setCategoryData({
      inputs: inputs.filter((el, elIndex) => elIndex !== index),
    });
  };
  return (
    <div className="categoryBuilder">
      <div onClick={handleDeleteCategory} className="deleteCategoryBtn">
        &times;
      </div>
      {isNameEditable ? (
        <input
          className="categoryBuilderName"
          defaultValue={name.length > 0 ? name : ""}
          placeholder={pHolder ? pHolder : null}
          onChange={handleNameChange}
          maxLength={20}
        />
      ) : displayName ? (
        <div className="categoryBuilderName">{name}</div>
      ) : null}
      <div className="categoryBuilderInputs">
        {inputs.map((input, index) => (
          <CategoryBuilderInput
            key={index}
            input={input}
            setInput={(input) => handleInputsChange({ input, index })}
            // if not variableSize dont pass deleteInput so cant delete (and knows it cant delete by checking whether passed deleteINput)
            deleteInput={isSizeVariable && deleteInput.bind(this, index)}
          />
        ))}
        {isSizeVariable && (
          <div className="addInputBtn" onClick={addInput}>
            <span>{t("addInput")}</span>
            <span className="plusSign">+</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryBuilder;

// const [state, dispatch] = useReducer((state, {input, index}) => {
//     let inputsCopy = [...state.inputs]
//     inputsCopy[index] = input;
//     return { inputs: inputsCopy}
// }, {inputs: Array.from({length: props.numInputs}, x => "")})
// function useArrayReducer(initialState) {
//     const [state, dispatch] = useReducer((state, {input, index}) => {
//         let inputsCopy = [...state.inputs]
//         inputsCopy[index] = input;
//         return { inputs: inputsCopy}
//     }, {inputs: Array.from({length: props.numInputs}, x => "")})
//     return [state, dispatch];
// }

import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import "../../css/ConfirmationDialog.scss";
/**
 * hook that manages a confirmation dialog. is passed a callback to handle user answers. it returns the JSX of a confirmationDialog and a function to open it. it also deals with storing the potential changes the user is considering while the user is considering them
 *
 * @param {(userAnswer: boolean, changesConsidered?: *) => *} handleUserResponse callback to handle users decision. it takes two arguments
 *
 * @returns {[confirmationDialogJSX, openDialog]} an array of length 2 with 1.the JSX of the confirmationDialog. 2. a function(openDialog) that accepts up to two arguments. It sets the content of/data for dialog then opens it
 * - openDialogs 2nd arg: changesBeingConsidered is optional. if passed the hook stores it while user is considering the changes and passes it back to caller with the users decision.

 */
function useConfirmationDialog(handleUserResponse) {
    const [open, setOpen] = useState(false);
    const [confirmationQuestion, setConfirmationQuestion] = useState("");
    const [changesBeingConsidered, setChangesBeingConsidered] = useState();

    /**
     * a function(openDialog) that accepts to two arguments. It sets the content of/data for dialog then opens it.
     * @param {string} confirmationQuestion text of question to be asked to the user
     * @param {*} [changesBeingConsidered] the potential changes user is to decide upon
     */
    const openDialog = (confirmationQuestion, changesBeingConsidered) => {
        setOpen(true);
        setConfirmationQuestion(confirmationQuestion);
        setChangesBeingConsidered(changesBeingConsidered);
    };

    function handleChoiceClicked(choice) {
        setOpen(false);
        handleUserResponse(choice, changesBeingConsidered);
    }

    const confirmationDialogJSX = (
        <ConfirmationDialog
            open={open}
            confirmationQuestion={confirmationQuestion}
            handleChoiceClicked={handleChoiceClicked}
        />
    );
    return [confirmationDialogJSX, openDialog];
}

function ConfirmationDialog({ open, confirmationQuestion, handleChoiceClicked, classes = "" }) {
    return (
        <Dialog open={open} className={`questionDialog confirmationDialog ${classes}`}>
            <div className="confirmationQuestion">{confirmationQuestion}</div>
            <div className="choicesList">
                <button onClick={() => handleChoiceClicked(true)} className="choiceBtn confirm">
                    Yes
                </button>
                <button onClick={() => handleChoiceClicked(false)} className="choiceBtn cancel">
                    Cancel
                </button>
            </div>
        </Dialog>
    );
}

export default useConfirmationDialog;
/* //choicesArr
// const choicesArr = [
//     ["Confirm", true],
//     ["Cancel", false]
// ]; //choicesArr elements have form of [text, returnValue].
// {choicesArr.map(([text, choiceValue], index) => (
//     <button key={index} onClick={() => handleChoiceClicked(choiceValue)} className={`${text.toLowerCase()} choiceBtn`} >
//         {text}
//     </button>
// ))} */

//* /QuestionDialog form
// function useQuestionDialog(choicesArr, handleUserResponse) {
//     const [open, setOpen] = useState(false);
//     const [questionText, setQuestionText] = useState("");
//     const [returnValue, setReturnValue] = useState(); //an extra value return when answer.
//     const openDialog = (questionText, returnValue) => {
//         /**
//          * opens dialog and is passed inforation about dialog
//          * @param {string} questionText text of question to be asked to the user
//          * @param returnValue a value to be passed back if
//          */
//         setOpen(true);
//         setQuestionText(questionText);
//         setReturnValue(returnValue);
//     };
//     const handleChoiceClicked = choice => {
//         setOpen(false);
//         handleUserResponse(choice, returnValue);
//     };
//     const questionDialogJSX = (
//         <QuestionDialog
//             open={open}
//             questionText={questionText}
//             choicesArr={choicesArr}
//             handleChoiceClicked={handleChoiceClicked}
//         />
//     );
//     return [openDialog, questionDialogJSX];
// }

// function QuestionDialog({ open, questionText, choicesArr, handleChoiceClicked }) {
//     return (
//         <Dialog open={open} className="questionDialog">
//             <DialogContent>{questionText}</DialogContent>
//             <DialogActions>
//                 {/* possibly convert to radio buttons */}
//                 {choicesArr.map(([text, returnValue], index) => (
//                     <button key={index} onClick={() => handleChoiceClicked(returnValue)}>
//                         {text}
//                     </button>
//                 ))}
//             </DialogActions>
//         </Dialog>
//     );
// }

// export default useQuestionDialog; */

// <div className="positioningWrapper">
//     <DialogContent>{questionText}</DialogContent>
//     <DialogActions>
//         {choicesArr.map(([text, returnValue], index) => (
//             <button key={index} onClick={() => handleChoiceClicked(returnValue)} className={text}>
//                 {text}
//             </button>
//         ))}
//     </DialogActions>
// </div>

// <DialogContent>{questionText}</DialogContent>
// <DialogActions>
//     {choicesArr.map(([text, returnValue], index) => (
//         <button key={index} onClick={() => handleChoiceClicked(returnValue)} className={text}>
//             {text}
//         </button>
//     ))}
// </DialogActions>
// <div>
//     <button>the quick brown fox jumped over the lazy dog</button>
// </div>
// <div>
//     <button>abc</button>
// </div>
// <button>abc</button>

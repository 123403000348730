import { useState, useEffect } from "react";
/**
 * hook to memoize a value lazily so after a dependency change it doesnt get recomputed until its accessed.
 *
 * useful for state involving rng which isnt accessed on most dependencies change but which should only change if dependencies do.
 * @param {(...dependenciesArray: any[]) => any } computeState
 * @param {any[]} [dependenciesArray=[]] the values on whose change state is recomputed.
 * note: dependencies array is passed as an argument to computeState so not putting in a dependency might cause screwups.
 * @returns {[getState, recomputeState]} a two length array with 1. a function to get the current state (with a recompute if dependencies have changed) and 2. a function to force a state recompute.
 */
function useLazyMemo(computeState, dependenciesArray = []) {
    const [state, setState] = useState();
    //only haveDependenciesChanged is true do you update state on getState call
    //manually check that depsArr has changed(diferent lengths or elements) bc react only accepts array literals in depsArr
    const [prevDependenciesArray, setPrevDependenciesArray] = useState([]);
    const [haveDependenciesChanged, setHaveDependenciesChanged] = useState(true);
    useEffect(() => {
        if (!Array.isArray(dependenciesArray)) throw TypeError("dependenciesArray is not array");
        if (
            dependenciesArray.length !== prevDependenciesArray.length ||
            dependenciesArray.some((dep, index) => dep !== prevDependenciesArray[index])
        ) {
            setHaveDependenciesChanged(true);
            setPrevDependenciesArray(dependenciesArray);
        }
    }, [prevDependenciesArray, dependenciesArray]);

    //returns state. if the deps have changed since last called then first recompute state.
    function getState() {
        if (haveDependenciesChanged) {
            return recomputeState();
        }
        return state;
    }

    //computes state and sets and sets dependenciesHaveChanged to false.
    /**
     * computes and returns a new state. resets dependenciesHaveChanged to false
     * @returns {any}
     */
    function recomputeState() {
        const newState = computeState(...dependenciesArray);
        setState(newState);
        console.log(1);
        setHaveDependenciesChanged(false);
        return newState;
    }
    return [getState, recomputeState];
}

export default useLazyMemo;

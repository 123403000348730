import React from "react";
import { useTranslate } from "../../translation/GPi18n";

const GameInstructions = (props) => {
  let instructions;
  const { t } = useTranslate();

  switch (props.game) {
    case "Bingo":
      instructions = (
        <div style={{ padding: "3vh 3vw" }}>{t("explainBingo")}</div>
      );
      break;
    case "Card Game":
      instructions = (
        <div style={{ padding: "3vh 3vw" }}>{t("explainCards")}</div>
      );
      break;
    case "Domino":
      instructions = (
        <div style={{ padding: "3vh 3vw" }}>{t("explainDomino")}</div>
      );
      break;
    case "Go Fish":
      instructions = (
        <div style={{ padding: "3vh 3vw" }}>{t("explainGoFish")}</div>
      );
      break;
    default:
      instructions = <div>{t("error")}</div>;
      break;
  }
  return instructions;
};
export default GameInstructions;

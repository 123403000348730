import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PixabayImages(props) {
    const direction = 'ltr';
    const [filter, setFilter] = useState("");
    const [lastFilter, setLastFilter] = useState();
    const [images, setImages] = useState([]);
    const [gotAll, setGotAll] = useState(false);



    const handleSearch = async () => {
        if (gotAll) {
            return;
        }
        // process.env.REACT_APP_PIXABAY_KEY = '16354300-44c494839198d49d1441ace47';
        const url = `https://pixabay.com/api/?key=16354300-44c494839198d49d1441ace47&q=${filter.split(" ").join("+")}&image_type=photo&safesearch=true&per_page=20&page=${filter === lastFilter ? images.length / 20 + 1 : 1}`;
        const res = await (await fetch(url)).json()
        if (res) {
            if (res.totalHits === images.length + res.hits.length) {
                setGotAll(true);
                if (filter === lastFilter) {
                }
                setImages(images => {
                    const result = [...images];
                    result.push(...res.hits);
                    return result;
                });
            } else {
                setImages(res.hits);
                setLastFilter(filter);
            }

        }
        else {
            console.log("error")
        }
    };

    const handleClick = () => {
        if (lastFilter === filter) {
            return;
        }
        let scrollImages = document.getElementsByClassName("dialog-content-scroll")[0];
        if (scrollImages) {
            scrollImages.scrollTop = 0;
        }
        setGotAll(false);
        handleSearch();
    }

    const handleScroll = e => {
        if (lastFilter !== filter || Math.abs(e.target.clientHeight + e.target.scrollTop - e.target.scrollHeight) > 12) {
            return;
        }
        handleSearch();
    }

    const handleKeyPress = e => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13 && lastFilter !== filter) {
            let scrollImages = document.getElementsByClassName("dialog-content-scroll")[0];
            if (scrollImages) {
                scrollImages.scrollTop = 0;
            }
            setGotAll(false);
            handleClick();
        }
    }

    const handleImageClick = image => {
        props.onChange({
            target: {
                value: {
                    webformatURL: image.webformatURL,
                    previewURL: image.previewURL
                }
            }
        }, filter);
        props.onClose();
    }

    return (
        <div className="pixabay-container"
            onScroll={handleScroll}
        >
            <div
                open={props.open}
                onClose={props.onClose}
                className="pixabay-images"
            >
                <div>
                    {/* <div>
                        <img className="pixabay-logo" src="https://pixabay.com/static/img/logo.svg" alt="pixabay logo"></img>
                    </div> */}
                    <div className="search-image">
                        <div className="search-icon-container"><FontAwesomeIcon icon="search" className="search-btn" onClick={handleClick} /></div>
                        <input className="filter-input" placeholder="pixabayImages.search" style={{ direction }} value={filter} onChange={e => setFilter(e.target.value)} onKeyDown={handleKeyPress} />
                    </div>
                </div>
                <div
                    onScroll={handleScroll}
                    className="dialog-content-scroll"
                >

                    {
                        images.length ?
                            <div className="images-wrapper">
                                {
                                    images.map((image, index) =>
                                        <img key={filter + index} src={image.previewURL} alt="option" className="image-option" onClick={e => handleImageClick(image, index)} />
                                    )
                                }
                            </div>
                            :
                            <em>
                                No images
                            </em>
                    }

                </div>
            </div>
        </div>

    )
}

export default PixabayImages;
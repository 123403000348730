import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/Home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gameTypes from "./gameTypes";
import { useTranslate } from "../translation/GPi18n";
import TopNavBar from "./NavBar";

/*
    the front page.
    has a header and a list of links to various games that can be built
    gets the list of games from gameTypes.js
    displays the list of games in a bootstrap collapse.
*/

const Home = () => {
  //get height and width to get aspect ratio of page. done to change whehter gameLinksCollapse has a width class
  //this class controls whether its collapse transition is vertical or horizontal
  //based on aspect ratio the display of the collapse changes and this is the easiest way ive found
  //probably TODO: rempove there is propbably a much better way to get the bootstrap collapse to work right. look intoo how works and how could be modified
  const orientation = usePageOrientation();
  const { t } = useTranslate();

  const getLogo = () => {
    let defLang = navigator.language.substring(0, 2);
    let lang = localStorage.getItem("lang") || defLang;

    if (!lang) {
      if (defLang == "he")
        return <img src="/icon/logoHe.png" className="hp-logo" alt="logo" />;
      if (defLang == "en")
        return <img src="/icon/logoEn.png" className="hp-logo" alt="logo" />;
      if (defLang == "ar")
        return <img src="/icon/logoHe.png" className="hp-logo" alt="logo" />;
      return <img src="/icon/logoEn.png" className="hp-logo" alt="logo" />;
    } else {
      if (lang == "he")
        return <img src="/icon/logoHe.png" className="hp-logo" alt="logo" />;
      if (lang == "en")
        return <img src="/icon/logoEn.png" className="hp-logo" alt="logo" />;
      if (lang == "ar")
        return <img src="/icon/logoHe.png" className="hp-logo" alt="logo" />;
      return <img src="/icon/logoEn.png" className="hp-logo" alt="logo" />;
    }
  };

  return (
    <div className="homePage">
      <TopNavBar />
      <a
        s
        href="https://funlearning.co.il/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {getLogo()}
      </a>
      <h3 className="homeHeader">
        {t("design")} {t("games")} <span>{t("personalized")}</span>{" "}
        {t("gamesForYourStudents")}
      </h3>
      <div className="gameLinksCollapseContainer">
        <button
          className="gameLinksCollapseBtn collapsed"
          data-toggle="collapse"
          data-target="#gameLinksCollapse"
        >
          <span>{t("createNewGame")}</span>
          <FontAwesomeIcon icon="angle-right" />
        </button>
        <div
          id="gameLinksCollapse"
          className={`collapse ${orientation === "landscape" ? "width" : ""}`}
        >
          <div className="gameLinks">
            {/* loop through gameTypes. the key is the name */}
            {Object.keys(gameTypes).map((gameName) => {
              //some games have whitespave in name.
              const nameNoWhiteSpace = gameName.replace(/\s/g, "");
              if (gameName == "Cube") {
                return (
                  <Link
                    className="gameLink"
                    to={{
                      pathname: "http://dicegenerator.funlearning.co.il/",
                    }}
                    target="_blank"
                  >
                    {" "}
                    {t(gameTypes[gameName].value)}
                  </Link>
                );
              } else
                return (
                  <Link
                    className="gameLink"
                    to={`/${nameNoWhiteSpace}`}
                    style={{ color: gameTypes[gameName].color }}
                    key={nameNoWhiteSpace}
                  >
                    {t(gameTypes[gameName].value)}
                  </Link>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;

//code copied from stack exchange
//go to top where used for explanation of why its in the component
function usePageOrientation() {
  function getPageOrientation() {
    return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
  }
  const [orientation, setWindowDimensions] = useState(getPageOrientation());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getPageOrientation());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return orientation;
}

import React from "react";
import InstructionsContent from "./Instructions.js";
import gameTypes from "../gameTypes";
import { useTranslate } from "../../translation/GPi18n";

function Instructions(props) {
  const { t } = useTranslate();
  return (
    <div>
      <div className="blur"></div>
      <div className="popupContainer" style={{ width: "50vw" }}>
        <div>{t(gameTypes[props.game].value).toUpperCase()}</div>
        <InstructionsContent game={props.game} />
        <div className="popupOptions">
          <div className="imgPopupOption" onClick={() => props.close(false)}>
            <img src="/icon/x.svg" alt="X" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Instructions;

import React, { useState, useContext } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import Flags from "country-flag-icons/react/3x2";
import { useTranslate } from "../translation/GPi18n";

import "../css/NavBar.scss";

const TopNavBar = (props) => {
  const { i18n } = useTranslate();
  const ClickLang = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  const getFlag = () => {
    let defLang = navigator.language.substring(0, 2);
    let lang = localStorage.getItem("lang") || defLang;

    if (!lang) {
      if (defLang == "he") return <Flags.IL className="flag" />;
      if (defLang == "en") return <Flags.US className="flag" />;
      if (defLang == "ar") return <Flags.AE className="flag" />;
    } else {
      if (lang == "he") return <Flags.IL className="flag" />;
      if (lang == "en") return <Flags.US className="flag" />;
      if (lang == "ar") return <Flags.AE className="flag" />;
    }
  };

  const getLang = () => {
    let defLang = navigator.language.substring(0, 2);
    let lang = localStorage.getItem("lang") || defLang;
    if (!lang) {
      if (defLang == "he") return "עברית";
      if (defLang == "en") return "English";
      if (defLang == "ar") return "عربيه";
    } else {
      if (lang == "he") return "עברית";
      if (lang == "en") return "English";
      if (lang == "ar") return "عربيه";
    }
  };

  return (
    <Navbar className="top_navbar">
      <Container>
        <Navbar.Brand id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">בית</Nav.Link>
            <Nav.Link href="https://courses.funlearning.co.il/">
              {" "}
              קורסים אונליין{" "}
            </Nav.Link>
            <Nav.Link href="https://funlearning.co.il/blog/">בלוג</Nav.Link>
            {/* <Nav.Link href="">מחוללי משחקים</Nav.Link> */}
            <Nav.Link href="https://game.funlearning.co.il/">
              מאגר משחקים חינוכיים
            </Nav.Link>
            <Nav.Link href="https://funlearning.co.il/%d7%90%d7%95%d7%93%d7%95%d7%aa/">
              אודותי
            </Nav.Link>
            <Nav.Link href="https://funlearning.co.il/contact/">
              צור קשר
            </Nav.Link>
          </Nav>
        </Navbar.Brand>
        <NavDropdown title={getLang()}>
          <NavDropdown.Item
            href=""
            onClick={() => {
              ClickLang("en");
            }}
          >
            English <Flags.US className="flag" />
          </NavDropdown.Item>

          <NavDropdown.Item
            href=""
            onClick={() => {
              ClickLang("he");
            }}
          >
            עברית <Flags.IL className="flag" />
          </NavDropdown.Item>

          <NavDropdown.Item
            href=""
            onClick={() => {
              ClickLang("ar");
            }}
          >
            عربيه <Flags.AE className="flag" />
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/"> {getFlag()}</Nav.Link>
      </Container>
    </Navbar>
  );
};

export default TopNavBar;

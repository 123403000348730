import React from "react";
import PixabayImages from './PixabayImages';
import ImageInput from "./ImageInput";


function ImagesPopup(props) {

    return <div>
        <div className="blur" />
        <div className="popupContainer">
            <div>CHOOSE A PHOTO</div>
            <div className="popupContent">
                <PixabayImages
                    open={props.open}
                    onClose={() => props.onPopupClose(false)}
                    onChange={(e, name) => props.onPixChange(e.target.value.webformatURL, name)} />
                <div className="popupSide">
                    <div>gallery</div>
                    <div>
                        <label className="imgInput">
                            upload
                        {/* only let users chamge the input if setContent passed (in edit mode) */}
                            {props.setContent && <ImageInput onChange={props.onImgChange} />}
                        </label>
                    </div>
                    <div style={{ color: "#8c5252" }} onClick={() => props.cancelImgChoice(true)}>remove image</div>

                </div>
            </div>
            <div className="popupOptions">
                {/* <div className="imgPopupOption" onClick={toggleImgOptionsAppearance}>done</div> */}
                <div className="imgPopupOption" onClick={() => props.cancelImgChoice(false)}><img src="/icon/x.svg" alt="X" /></div>
            </div>
        </div>
    </div>
}
export default ImagesPopup
import React from "react";


function RecoverPopup(props) {

    return <div>
        <div className="blur" />
        <div className="popupContainer recoverPopup">
            <div>RECOVER LAST {props.name.toUpperCase()} GAME?</div>

            <div className="popupOptions">
                <div className="imgPopupOption" onClick={() => props.close()}><img src="/icon/x.svg" alt="X" /></div>
            </div>
            <div className="recoverPopupContent">
                You have an unfinished version of this game in the system.<br />
        would you like to continue building it?
        </div>
            <div className="popupOptions">
                <div className="choiceBtn" onClick={() => props.recover(true)}>recover</div>
                <div className="choiceBtn" onClick={() => props.recover(false)}>discard</div>
            </div>
        </div>
    </div>
}
export default RecoverPopup
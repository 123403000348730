import React, { createContext } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import GameCards from "./components/GameBuilders/GameCards";
import Dominoes from "./components/GameBuilders/Dominoes";
import Bingo from "./components/GameBuilders/Bingo";
import GoFish from "./components/GameBuilders/GoFish";
import gameTypes from "./components/gameTypes";
import { translate } from "./translation/GPi18n";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            path="/CardGame"
            render={() => <GameCards gameInfo={gameTypes["Card Game"]} />}
          />
          <Route
            path="/Domino"
            render={() => <Dominoes gameInfo={gameTypes["Domino"]} />}
          />
          <Route
            path="/Bingo"
            render={() => <Bingo gameInfo={gameTypes["Bingo"]} />}
          />
          <Route
            path="/gofish"
            render={() => <GoFish gameInfo={gameTypes["Go Fish"]} />}
          />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
      <div className="mobile">This website is available on desktop only.</div>
    </div>
  );
}

export default translate(App);

// // games are in a wrapper component that gives them a sidebar and some other stuff (go to GamePage.jsx for more)
// function App2() {
//     return (
//         <div className="App">
//             <Router>
//                 <Switch>
//                     <Route path="/gameCard" render={() => <GamePage GameBuilder={GameCards} game="Game Card" />} />
//                     <Route path="/Domino" render={() => <GamePage GameBuilder={Dominoes} game="Domino" />} />
//                     <Route path="/Bingo" render={() => <GamePage GameBuilder={Bingo} game="Bingo" />} />
//                     <Route path="/gofish" render={() => <GamePage GameBuilder={GoFish} game="Go Fish" />} />
//                     <Route path="/" component={Home} />
//                 </Switch>
//             </Router>
//         </div>
//     );
// }
//possibly TODO in GamePage: change (props) to ({Main, ...others} and pass other down) or change structure (might)
//posible TODO (from beginning: change structure of how gamePages are set (possibly hoc or renderprops or custom hooks))
//Posible TODO put topbar and sidebar in a component together
//TODO set up font sizes with break points and the like (preferably before so far in that changing everything is nearly impossible)
// possibly TODO: create a styled radio button component. (frontback and sidebar)
// possible TODO: make things like category and category inputs actual objects (with constructor.factor function and stuff)
//instead of just pushing it on with certain values given (eg createCategory(v1,v2) instead of  having {k: p, k:p})
//TODO create color picker
//TODO make categories variable size

import React from "react";
import "../../css/DecoratableCard.scss";

//gets passed a logo, a title  and content
//title and logo are often choosable
//has a bunch of css stuff associated with it
//exists because
//    a)  bunch of styles are shared between things in different components
//    b) when get to decorating cards having a single component that is used for displaying all cards with probably make it easier
const DecoratableCard = ({ className = "", logo, title, content, frame, style = {} }) => {
    return (
        <div className={`decoratableCard ${className}`} style={{ ...style }}>
            <div className="logoContainer">
                <img src="/icon/logoNewNoWording.png" alt="logo" />
                {/* {logo} */}
            </div>
            {/* only display the hr if their is a title
            (the components that dont have titles on their cards also dont have the hr */}
            {title && (
                <>
                    <div className="title">{title}</div>
                    {title.props && title.props.children && title.props.children.length > 0 && <hr />}
                </>
            )}
            {frame.frame.length > 0 && <img className="frame" src={frame.frame} alt="frame" />}
            {frame.vector.length > 0 && <img className="vector" src={frame.vector} alt="vector" />}

            <div className={`content`}>{content}</div>
        </div>
    );
};

export default DecoratableCard;

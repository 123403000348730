import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ExplanationPopup from "../Assorted/instructionPopup";
// import { LanguageContext } from "../Assorted/LanguageChange";
import { useTranslate } from "../../translation/GPi18n";
import gameTypes from "../gameTypes";

//possibly switch from FontawesomeIcon back to the Cloud

//top bar has a button to go back to home page and text saying what page your on (eg domino-view)
//the text of page is colored based on what game it is
//props passed are topbarText and textColor
//the go home button is displayed on far left (in wide layout centered over sidebar)
const Topbar = (props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslate();

  return (
    <div className="topbar">
      <Link to="/" className="goToHomeBtn">
        <img
          src={"/icon/logoNewNoWording.png"}
          alt="button to back to home page"
        />
      </Link>
      <span onClick={() => setOpen(true)}>
        {t(props.value) + " "}-{" " + t(props.modeName)}
      </span>
      {/* {languageChangeButtons} */}
      <span onClick={() => setOpen(true)} className="info">
        i
      </span>
      {open && (
        <ExplanationPopup game={props.gameName} close={() => setOpen(false)} />
      )}
    </div>
  );
};

export default Topbar;
